import {useQuery} from '@apollo/client';
import React, {memo} from 'react';
import {ErrorComponent} from '../../../../../Component/Error/ErrorComponent';
import {LoadingComponent} from '../../../../../Component/Loading/LoadingComponent';
import {getOrganisationSelectQuery} from '../../../../GraphQL/Query/getOrganisationSelectQuery';
import {getOrganisationSelect} from '../../../../GraphQL/Query/types/getOrganisationSelect';
import {FormFieldProps} from '../../../../Model/Form/FormFieldProps';
import {CheckboxGroup} from '../CheckboxGroupField';
import {SelectField} from '../SelectField';

interface Props extends FormFieldProps {
  multiple?: boolean;
  value: string | string[];
}

export const OrganisationField: React.FC<Props> = memo(props => {
  const {loading, error, data} = useQuery<getOrganisationSelect>(getOrganisationSelectQuery);

  if (loading) {
    return <LoadingComponent/>;
  }
  if (error || !data) {
    return <ErrorComponent/>;
  }
  const options = data.organisations.map(organisation => ({key: organisation.id, label: organisation.name}));
  if (props.multiple) {
    return <CheckboxGroup type="number" options={options} {...props}/>;
  }
  return <SelectField options={options} {...props}/>;
});
