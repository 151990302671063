import {CommandTypes} from '../../Model/Queue/CommandType';
import {QueueStatus} from '../../Model/Queue/QueueStatus';
import {createUploadFavoritesQueueItem} from '../../Service/Queue/Command/QueueItemFactory';
import {QueueGateway} from '../../Service/Queue/QueueGateway';
import {Middleware} from '../index';
import {FavoriteType} from './action';

export class FavoriteMiddleware {
  public constructor(private queueGateway: QueueGateway) {
  }

  public middleware: Middleware = store => next => action => {
    next(action);

    switch (action.type) {
      case FavoriteType.FavoriteProductRemoved:
      case FavoriteType.FavoriteProductAdded:
        if (!store.getState().queue.find(({status, commandType}) => commandType === CommandTypes.UploadFavorites && status === QueueStatus.Queued)) {
          this.queueGateway.add(createUploadFavoritesQueueItem());
        }
    }
  };
}
