import {DateTime} from 'luxon';
import {ordersQuery} from '../../../GraphQL/Query/ordersQuery';
import {OrdersQuery, OrdersQueryVariables} from '../../../GraphQL/Query/types/OrdersQuery';
import {QueueCommand} from '../../../Model/Queue/QueueCommand';
import {QueueStatus} from '../../../Model/Queue/QueueStatus';
import {ordersLoaded} from '../../../Store/Order/action';
import {GraphQlClient} from '../../GraphQl/GraphQlClient';
import {StoreGateway} from '../../Store/StoreGateway';

export class DownloadOrdersCommand implements QueueCommand {
  public constructor(private graphQlClient: GraphQlClient, private storeGateway: StoreGateway) {
  }

  public async execute(): Promise<QueueStatus> {
    try {
      const result = await this.graphQlClient.getClient().query<OrdersQuery, OrdersQueryVariables>({
        query: ordersQuery,
        fetchPolicy: 'network-only',
        variables: {deliveryDate: DateTime.local().minus({days: 7}).toFormat('yyyy-LL-dd')},
      });
      if (!result) {
        return QueueStatus.Failed;
      }
      this.storeGateway.dispatch(ordersLoaded(result.data.orders));
      return QueueStatus.Done;
    } catch (e) {
      console.error(e);
    }
    return QueueStatus.Retry;
  }
}
