import {DateTime} from 'luxon';
import {CartItem} from '../../Model/CartItem';

export enum CartType {
  Increased = 'increased',
  Decreased = 'decreased',
  CartLoaded = 'cartLoaded',
  RemovedProduct = 'removedProduct',
  QuantitySet = 'quantitySet',
  RemarkSet = 'remarkSet',
  DeliveryDateSet = 'deliveryDateSet',
}

export interface CartIncreasedAction {
  productId: number;
  type: CartType.Increased;
}

export interface CartDecreasedAction {
  productId: number;
  type: CartType.Decreased;
}

export interface CartLoadedAction {
  cart: CartItem[];
  type: CartType.CartLoaded;
}

export interface CartRemovedProductAction {
  productId: number;
  type: CartType.RemovedProduct;
}

export interface CartQuantitySetAction {
  productId: number;
  quantity: number;
  type: CartType.QuantitySet;
}

export interface RemarkSetAction {
  remark: string;
  type: CartType.RemarkSet;
}

export interface DeliveryDateSetAction {
  deliveryDate: DateTime;
  type: CartType.DeliveryDateSet;
}

export const increased = (productId: number): CartIncreasedAction => ({
  type: CartType.Increased,
  productId,
});

export const decreased = (productId: number): CartDecreasedAction => ({
  type: CartType.Decreased,
  productId,
});

export const cartLoaded = (cart: CartItem[]): CartLoadedAction => ({
  type: CartType.CartLoaded,
  cart,
});

export const removedProductFromCart = (productId: number): CartRemovedProductAction => ({
  type: CartType.RemovedProduct,
  productId,
});

export const quantitySet = (productId: number, quantity: number): CartQuantitySetAction => ({
  type: CartType.QuantitySet,
  productId,
  quantity,
});

export const remarkSet = (remark: string): RemarkSetAction => ({
  type: CartType.RemarkSet,
  remark,
});

export const deliveryDateSet = (deliveryDate: DateTime): DeliveryDateSetAction => ({
  type: CartType.DeliveryDateSet,
  deliveryDate,
});

export type CartActions =
    | CartIncreasedAction
    | CartDecreasedAction
    | CartLoadedAction
    | CartRemovedProductAction
    | CartQuantitySetAction
    | RemarkSetAction
    | DeliveryDateSetAction;
