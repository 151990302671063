import React, {memo} from 'react';
import {FormFieldProps} from '../../../Model/Form/FormFieldProps';
import {TextField} from './TextField';

interface Props extends FormFieldProps {
  value: string | undefined | null;
}

export const EmailField: React.FC<Props> = memo(props => {
  let {attributes, ...passthroughProps} = props;
  attributes = {type: 'email', pattern: 'email', ...attributes};
  return (
    <TextField {...passthroughProps} attributes={attributes}/>
  );
});
