import {TypeKind} from 'graphql';
import React, {memo} from 'react';
import {UnknownFieldTypeError} from '../../../Error/UnknownFieldTypeError';
import {FieldDefinition} from '../../../Model/Form/FieldDefinition';
import {FormError} from '../../../Model/Form/FormError';
import {FormValue} from '../../../Model/Form/FormValue';
import {GraphField} from '../../../Model/Form/GraphField';
import {OnChange} from '../../../Model/Form/OnChange';
import {TypeName} from '../../../Model/GraphQL/TypeName';
import {extractEnumFormOptions} from '../../../Service/Form/EnumOptionsExtractor';
import {CheckboxGroup} from '../Field/CheckboxGroupField';
import {CategoryField} from '../Field/Custom/CategoryField';
import {OrganisationField} from '../Field/Custom/OrganisationField';
import {StockField} from '../Field/Custom/StockField';
import {DecimalField} from '../Field/DecimalField';
import {EmailField} from '../Field/EmailField';
import {ImageField} from '../Field/ImageField';
import {IntegerField} from '../Field/IntegerField';
import {PasswordField} from '../Field/PasswordField';
import {SelectField} from '../Field/SelectField';
import {TextField} from '../Field/TextField';
import {Field} from './Field';

interface Props {
  errors: FormError[];
  field: FieldDefinition;
  onChange: OnChange;
  value: FormValue;
}

const isRequired = (field: FieldDefinition): boolean => field.type.kind === TypeKind.NON_NULL;

const getFieldFromName = (field: FieldDefinition, value: FormValue, onChange: OnChange) => {
  switch (field.name) {
    case 'id':
      return null;
    case 'email':
      return (
        <EmailField
          name="email"
          value={value as any}
          onChange={onChange}
          required={isRequired(field)}
        />
      );
    case 'plainPassword':
      return (
        <PasswordField
          name="plainPassword"
          value={value as any}
          onChange={onChange}
          required={isRequired(field)}
        />
      );
    case 'organisationId':
      return (
        <OrganisationField
          name="organisationId"
          value={value as any}
          onChange={onChange}
          required={isRequired(field)}
        />
      );
    case 'organisations':
      return (
        <OrganisationField
          name="organisations"
          value={value as any}
          multiple
          onChange={onChange}
          required={isRequired(field)}
        />
      );
    case 'categoryId':
      return (
        <CategoryField
          name="categoryId"
          value={value as any}
          onChange={onChange}
          required={isRequired(field)}
        />
      );
    case 'categories':
      return (
        <CategoryField
          name="categoryId"
          value={value as any}
          multiple
          onChange={onChange}
          required={isRequired(field)}
        />
      );
    case 'image':
      return (
        <ImageField
          name="image"
          value={value as any}
          onChange={onChange}
          required={isRequired(field)}
        />
      );
    case 'stock':
      return (
        <StockField
          name="stock"
          value={value as any}
          onChange={onChange}
          required={isRequired(field)}
        />
      );
  }
  throw new UnknownFieldTypeError();
};

const getFieldFromScalar = (name: string, field: GraphField, value: FormValue, onChange: OnChange, required: boolean) => {
  switch (field.name) {
    case TypeName.String:
      return <TextField name={name} onChange={onChange} value={value as any} required={required}/>;
    case TypeName.Int:
      return <IntegerField name={name} onChange={onChange} value={value as any} required={required}/>;
    case TypeName.Float:
      return <DecimalField name={name} onChange={onChange} value={value as any} required={required}/>;
  }
  throw new UnknownFieldTypeError();
};

const getFieldFromDefinition = (name: string, field: GraphField, value: FormValue, onChange: OnChange, required: boolean): JSX.Element => {
  switch (field.kind) {
    case TypeKind.NON_NULL:
      return getFieldFromDefinition(name, field.ofType, value, onChange, true);
    case TypeKind.SCALAR:
      return getFieldFromScalar(name, field, value, onChange, required);
    case TypeKind.LIST:
      if (field.ofType.kind !== TypeKind.ENUM) {
        throw new UnknownFieldTypeError();
      }
      return (
        <CheckboxGroup
          name={name}
          type="string"
          onChange={onChange}
          value={value}
          required={required}
          options={extractEnumFormOptions(name, field.ofType)}
        />
      );
    case TypeKind.ENUM:
      return (
        <SelectField
          name={name}
          onChange={onChange}
          value={value}
          required={required}
          options={extractEnumFormOptions(name, field)}
        />
      );
  }
  throw new UnknownFieldTypeError();
};

const getField = (field: FieldDefinition, value: FormValue, onChange: OnChange): React.ReactNode => {
  try {
    return getFieldFromName(field, value, onChange);
  } catch (e) {
    // do nothing
  }
  return getFieldFromDefinition(field.name, field.type, value, onChange, false);
};

export const DynamicFormField: React.FC<Props> = memo(({
  field, value, onChange, errors,
}) => {
  try {
    const fieldComponent = getField(field, value, onChange);
    if (!fieldComponent) {
      return null;
    }
    return (
      <Field name={field.name} errors={errors} required={isRequired(field)}>
        {fieldComponent}
      </Field>
    );
  } catch (e) {
    return null;
  }
});
