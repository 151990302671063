import {DateTime} from 'luxon';
import React, {memo} from 'react';
import {DateComponent} from '../../../Component/Date/DateComponent';
import {formatOrderNumber} from '../../../Utils/OrderNumberFormatter';
import {AdminListPage} from '../../Component/Page/AdminListPage';
import {getOrderListQuery} from '../../GraphQL/Query/getOrderListQuery';
import {ColumnFormatting} from '../../Model/Form/ColumnFormatting';
import {Order} from '../../../Model/Order';

const columnFormatting: ColumnFormatting = {
  deliveryDate: row => {
    const {deliveryDate} = row;
    return <DateComponent date={deliveryDate}/>;
  },
  orderId: row => formatOrderNumber(row.orderId),
};

export const OrderAdminListScreen: React.FC = memo(() => (
  <AdminListPage
    entityType="order"
    getQuery={getOrderListQuery}
    disableAdd
    queryVariables={{deliveryDate: DateTime.local().toFormat('yyyy-LL-dd')}}
    columnFormatting={columnFormatting}
    sortFunction={(a: Order, b: Order) => (a.deliveryDate > b.deliveryDate ? 1 : a.deliveryDate < b.deliveryDate ? -1 : 0)}
  />
));
