import {getFirstAvailableDeliveryDate} from '../../Service/DeliveryDate/DeliveryDate';
import {AuthenticationType} from '../Authentication/action';
import {Action} from '../action';
import {OrderType} from '../Order/action';
import {CartType} from './action';
import {CartState} from './state';

const initialState: CartState = {
  remark: '',
  deliveryDate: getFirstAvailableDeliveryDate(),
  cartItems: [],
};

export const cartReducer = (state = initialState, action: Action): CartState => {
  let cartItems;
  switch (action.type) {
    case CartType.Increased:
      if (state.cartItems.find(({productId}) => productId === action.productId)) {
        cartItems = state.cartItems.map(result => (result.productId === action.productId ? {...result, amount: result.amount + 1} : result));
        return {...state, cartItems};
      }
      return {...state, cartItems: [...state.cartItems, {productId: action.productId, amount: 1}]};

    case CartType.Decreased:
      cartItems = state.cartItems.map(result => (result.productId === action.productId ? {...result, amount: result.amount - 1} : result));
      return {...state, cartItems: cartItems.filter(result => result.amount > 0)};

    case CartType.RemovedProduct:
      return {...state, cartItems: state.cartItems.filter(({productId}) => productId !== action.productId)};

    case CartType.QuantitySet:
      cartItems = state.cartItems.map(result => (result.productId === action.productId ? {...result, amount: action.quantity} : result));
      return {...state, cartItems: cartItems.filter(result => result.amount > 0)};

    case CartType.CartLoaded:
      return {...state, cartItems: action.cart};

    case CartType.RemarkSet:
      return {...state, remark: action.remark};

    case CartType.DeliveryDateSet:
      return {...state, deliveryDate: action.deliveryDate};

    case AuthenticationType.Authenticated:
      return action.isNewUser ? initialState : state;

    case OrderType.OrderPlaced:
      return initialState;
    default:
      return state;
  }
};
