import {Sleep} from '../../Utils/Sleep';
import {QueueWorker} from './QueueWorker';

export class AsyncWorker {
  private promise: Promise<void> | null = null;

  private running = true;

  public constructor(private queueWorker: QueueWorker, private sleep: Sleep) {
  }

  public isRunning(): boolean {
    return this.running;
  }

  public async start(): Promise<void> {
    this.running = true;
    while (this.running) {
      try {
        this.promise = this.queueWorker.work();
        await this.promise;
      } catch (e) {
        await this.sleep(2000);
      }
    }
  }

  public async stop(): Promise<void> {
    this.running = false;
    if (this.promise) {
      await this.promise;
    }
  }
}
