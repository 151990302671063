import {gql} from 'apollo-boost';

export const addOrderMutation = gql`
    mutation AddOrder($deliveryDate: DateTime!, $remark: String!, $orderLines: [NewOrderLineInput!]!, $uuid: String!){
        addOrder(data: {
            deliveryDate: $deliveryDate,
            remark: $remark,
            orderLines: $orderLines,
            uuid: $uuid
        }) {
            id
            orderDate
        }
    }
`;
