import {useQuery} from '@apollo/client';
import React, {memo} from 'react';
import {RouteComponentProps} from 'react-router';
import {ErrorComponent} from '../../../Component/Error/ErrorComponent';
import {LoadingComponent} from '../../../Component/Loading/LoadingComponent';
import {OrderDetailComponent} from '../../Component/Order/OrderDetailComponent';
import {AdminPage} from '../../Component/Page/AdminPage';
import {getOrderDetailQuery} from '../../GraphQL/Query/getOrderDetailQuery';
import {GetOrderDetailQuery, GetOrderDetailQueryVariables} from '../../GraphQL/Query/types/GetOrderDetailQuery';

type Props = RouteComponentProps<{
  id: string;
}>;

export const OrderAdminDetailScreen: React.FC<Props> = memo(({match}) => {
  const {loading, error, data} = useQuery<GetOrderDetailQuery, GetOrderDetailQueryVariables>(getOrderDetailQuery, {
    fetchPolicy: 'network-only',
    variables: {id: parseInt(match.params.id, 10)},
  });

  return (
    <AdminPage title="Admin.order.PageTitle.Detail" backUrl="/admin/order">
      {loading && <LoadingComponent/>}
      {(!loading && (error || !data)) && <ErrorComponent/>}
      {data?.order && <OrderDetailComponent order={data.order}/>}
    </AdminPage>
  );
}, (prevProps, nextProps) => prevProps.match.params.id === nextProps.match.params.id);

