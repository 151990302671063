import {Device} from '@capacitor/device';
import {PlatformEnum} from '../Model/PlatformEnum';

export const useIsPlatform = async (platform: PlatformEnum): Promise<boolean> => {
  const info = await Device.getInfo();

  if (!info) {
    return false;
  }

  return info.platform === platform;
};
