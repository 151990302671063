import {UpdateCart, UpdateCartVariables} from '../../../GraphQL/Mutation/types/UpdateCart';
import {updateCartMutation} from '../../../GraphQL/Mutation/updateCartMutation';
import {QueueCommand} from '../../../Model/Queue/QueueCommand';
import {QueueStatus} from '../../../Model/Queue/QueueStatus';
import {GraphQlClient} from '../../GraphQl/GraphQlClient';
import {StoreGateway} from '../../Store/StoreGateway';

export class UploadCartCommand implements QueueCommand {
  public constructor(private graphQlClient: GraphQlClient, private storeGateway: StoreGateway) {
  }

  public async execute(): Promise<QueueStatus> {
    try {
      const cartLines = this.storeGateway.getState().cart.cartItems;
      await this.graphQlClient.getClient().mutate<UpdateCart, UpdateCartVariables>({
        mutation: updateCartMutation,
        variables: {cartLines},
      });
      return QueueStatus.Done;
    } catch (e) {
      console.error(e);
    }
    return QueueStatus.Retry;
  }
}
