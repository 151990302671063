import {
  IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar,
} from '@ionic/react';
import React, {memo, useCallback, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {SearchBarComponent} from '../Component/Search/SearchBarComponent';
import {SearchResultComponent} from '../Component/Search/SearchResultComponent';
import {CartFooterContainer} from '../Container/Component/CartFooterContainer';

export const SearchScreen: React.FC = memo(() => {
  const [searchValue, setSearchValue] = useState('');
  const onInput = useCallback((input: string) => setSearchValue(input), []);
  const {formatMessage} = useIntl();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton text={formatMessage({id: 'Label.Back'})} defaultHref="/categories"/>
          </IonButtons>
          <IonTitle><FormattedMessage id="Title.Search"/></IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="custom-search">
          <SearchBarComponent onInput={onInput} minCharacters={3}/>
          {searchValue && <SearchResultComponent searchValue={searchValue}/>}
        </div>
      </IonContent>
      <CartFooterContainer/>
    </IonPage>
  );
});
