import {QueueGateway} from './QueueGateway';
import {QueueItemConsumer} from './QueueItemConsumer';

export class QueueWorker {
  public constructor(private gateway: QueueGateway, private consumer: QueueItemConsumer) {
  }

  public async work(): Promise<void> {
    const item = await this.gateway.getNext();
    await this.consumer.processCommand(item);
  }
}
