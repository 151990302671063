import {
  IonItem, IonLabel, IonList, IonRadio, IonRadioGroup,
} from '@ionic/react';
import React, {memo, useCallback} from 'react';
import {FormattedMessage} from 'react-intl';
import {FormFieldOption} from '../../../Model/Form/FormFieldOption';
import {FormFieldProps} from '../../../Model/Form/FormFieldProps';
import {FormValue} from '../../../Model/Form/FormValue';
import {RequiredComponent} from '../Layout/Required';

interface Props extends FormFieldProps {
  options: FormFieldOption[];
  value: FormValue;
}

export const RadioGroupField: React.FC<Props> = memo(({
  name, value, options, onChange, required,
}) => {
  const callback = useCallback(e => onChange((e.target).value), [onChange]);
  const radios = options.map(option => (
    <IonItem key={option.key}>
      <IonLabel>{option.label}</IonLabel>
      <IonRadio slot="start" name={name} value={option.key}/>
    </IonItem>
  ));

  return (
    <IonList>
      <IonRadioGroup value={value} onIonChange={callback}>
        <IonLabel>
          <FormattedMessage id={`Admin.Form.Field.${name}.Label`}/>
          <RequiredComponent required={required}/>
        </IonLabel>
        {radios}
      </IonRadioGroup>
    </IonList>
  );
});
