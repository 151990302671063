export const getBaseEncodedFile = (file: File): Promise<string> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    if (typeof reader.result === 'string') {
      resolve(reader.result);
    }
    reject();
  };
  reader.onerror = error => reject(error);
});
