import {IonItem} from '@ionic/react';
import React, {memo} from 'react';
import {OrderLine} from '../../Model/OrderLine';

interface Props {
  orderLine: OrderLine;
}

export const OrderLineDetailComponent: React.FC<Props> = memo(({orderLine}) => (
  <IonItem key={orderLine.id} className="ion-no-padding">
    <div className="product product--small">
      <div className="product__specs">
        <div className="product__name">
          {orderLine.productName}
        </div>
        <div className="product__amount">
          {orderLine.amount}
        </div>
      </div>
    </div>
  </IonItem>
));
