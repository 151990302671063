import {gql} from 'apollo-boost';

export const getProductListQuery = gql`
    query getProductList{
        products{
            id
            name
            category {
                id
                name
            }
            priority
        }
    }
`;
