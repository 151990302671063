export enum FavoriteType {
  FavoriteProductAdded = 'FavoriteProductAdded',
  FavoriteProductRemoved = 'FavoriteProductRemoved',
  FavoritesLoaded = 'FavoritesLoaded',
}

export interface FavoriteProductAddedAction {
  productId: number;
  type: FavoriteType.FavoriteProductAdded;
}

export interface FavoriteProductRemovedAction {
  productId: number;
  type: FavoriteType.FavoriteProductRemoved;
}

export interface FavoritesLoadedAction {
  favorites: number[];
  type: FavoriteType.FavoritesLoaded;
}

export const favoriteProductAdded = (productId: number): FavoriteProductAddedAction => ({
  type: FavoriteType.FavoriteProductAdded,
  productId,
});

export const favoriteProductRemoved = (productId: number): FavoriteProductRemovedAction => ({
  type: FavoriteType.FavoriteProductRemoved,
  productId,
});

export const favoritesLoaded = (favorites: number[]): FavoritesLoadedAction => ({
  type: FavoriteType.FavoritesLoaded,
  favorites,
});

export type FavoriteActions =
    | FavoriteProductAddedAction
    | FavoriteProductRemovedAction
    | FavoritesLoadedAction;
