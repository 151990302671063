import {gql} from 'apollo-boost';

export const getOrderListQuery = gql`
    query GetOrderList($deliveryDate: String!) {
        orders(where: {deliveryDate:{gte: $deliveryDate}}){
            id
            orderId: id
            user {
                id
                organisation {
                    id
                    name
                }
            }
            deliveryDate
        }
    }
`;
