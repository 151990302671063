import {Action} from '../../Store/action';
import {ToastType} from './action';
import {ToastState} from './state';

const initialState: ToastState = [];

export const toastReducer = (state = initialState, action: Action): ToastState => {
  switch (action.type) {
    case ToastType.ShowToast:
      return [...state, action.toast];
    case ToastType.HideToast:
      return state.filter(toast => toast.id !== action.toastId);
    default:
      return state;
  }
};
