import React, {memo} from 'react';
import {AdminPage} from './AdminPage';

interface Props {
  action: string;
  entityType: string;
}

export const AdminCrudPage: React.FC<Props> = memo(({entityType, action, children}) => {
  let backUrl = `/admin/${entityType}`;
  if (action === 'List') {
    backUrl = '/admin';
  }
  return (
    <AdminPage title={`Admin.${entityType}.PageTitle.${action}`} backUrl={backUrl}>
      {children}
    </AdminPage>
  );
});
