import {IonButton} from '@ionic/react';
import {DateTime} from 'luxon';
import React, {memo, useCallback} from 'react';
import {FormattedMessage} from 'react-intl';
import {useSelector} from 'react-redux';
import {Redirect} from 'react-router';
import {useCartProducts} from '../../Hook/UseCartProducts';
import {usePlaceOrder} from '../../Hook/UsePlaceOrder';
import {getFirstAvailableDeliveryDate} from '../../Service/DeliveryDate/DeliveryDate';
import {RootState} from '../../Store';

export const ConfirmOrderButtonComponent: React.FC = memo(() => {
  const remark = useSelector<RootState, string>(state => state.cart.remark);
  const deliveryDate = useSelector<RootState, DateTime>(state => state.cart.deliveryDate);

  const [hasOrdered, placeOrder] = usePlaceOrder(deliveryDate.toString(), remark);
  const cartProducts = useCartProducts();

  const canPlaceOrder = useCallback(() => {
    if (deliveryDate.weekday === 7) {
      return false;
    }

    if (deliveryDate.toISODate() < getFirstAvailableDeliveryDate().toISODate()) {
      return false;
    }

    if (cartProducts.length === 0) {
      return false;
    }

    for (const cartProduct of cartProducts) {
      if (cartProduct.stock === 0) {
        return false;
      }
    }

    return true;
  }, [deliveryDate, cartProducts]);

  if (hasOrdered) {
    return <Redirect to="/confirmation"/>;
  }

  return (
    <IonButton className="ion-button--margin-small" disabled={!canPlaceOrder()} onClick={placeOrder} color="secondary" expand="full">
      <FormattedMessage id="Label.Order"/>
    </IonButton>
  );
});
