import {UpdateFavorites, UpdateFavoritesVariables} from '../../../GraphQL/Mutation/types/UpdateFavorites';
import {updateFavoritesMutation} from '../../../GraphQL/Mutation/updateFavoritesMutation';
import {QueueCommand} from '../../../Model/Queue/QueueCommand';
import {QueueStatus} from '../../../Model/Queue/QueueStatus';
import {GraphQlClient} from '../../GraphQl/GraphQlClient';
import {StoreGateway} from '../../Store/StoreGateway';

export class UploadFavoritesCommand implements QueueCommand {
  public constructor(private graphQlClient: GraphQlClient, private storeGateway: StoreGateway) {
  }

  public async execute(): Promise<QueueStatus> {
    try {
      const favorites = this.storeGateway.getState().favorite;
      await this.graphQlClient.getClient().mutate<UpdateFavorites, UpdateFavoritesVariables>({
        mutation: updateFavoritesMutation,
        variables: {favorites},
      });
      return QueueStatus.Done;
    } catch (e) {
      console.error(e);
    }
    return QueueStatus.Retry;
  }
}
