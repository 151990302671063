import {queueRunnerStarted} from '../../Store/Queue/action';
import {Sleep} from '../../Utils/Sleep';
import {StoreGateway} from '../Store/StoreGateway';
import {AsyncWorker} from './AsyncWorker';
import {QueueWorker} from './QueueWorker';

export class QueueRunner {
  private running = false;

  private workers: AsyncWorker[] = [];

  public constructor(private queueWorker: QueueWorker, private sleep: Sleep, private storeGateway: StoreGateway) {
  }

  public isRunning(): boolean {
    return this.running;
  }

  public start(amountOfWorkers: number): void {
    this.storeGateway.dispatch(queueRunnerStarted());
    this.running = true;
    for (let i = 0; i < amountOfWorkers; i++) {
      const worker = new AsyncWorker(this.queueWorker, this.sleep);
      worker.start();
      this.workers.push(worker);
    }
  }

  public async stop(): Promise<void> {
    await Promise.all(this.workers.map(worker => worker.stop()));
    this.running = false;
    this.workers = [];
  }
}
