import {IonButton, IonIcon, IonInput, IonItem, IonLabel, IonList, IonNote} from '@ionic/react';
import {useMutation} from '@apollo/client';
import {key, person} from 'ionicons/icons';
import React, {FormEvent, memo, useCallback, useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Redirect} from 'react-router-dom';
import {AuthenticationFormContainerProps} from '../../Container/Component/AuthenticationFormContainer';
import {loginMutation} from '../../GraphQL/Mutation/loginMutation';
import {LoginMutation, LoginMutationVariables} from '../../GraphQL/Mutation/types/LoginMutation';

export const AuthenticationFormComponent: React.FC<AuthenticationFormContainerProps> = memo(({
  usernameEntered,
  tokenReceived,
}) => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [token, setToken] = useState<undefined | string>();

  const [authenticate] = useMutation<LoginMutation, LoginMutationVariables>(loginMutation, {
    onCompleted({login}) {
      setToken(login);
    },
    onError() {
      setPassword('');
      setError(true);
    },
  });

  const usernameChanged = useCallback(({detail}) => setUsername(detail.value), []);
  const passwordChanged = useCallback(({detail}) => setPassword(detail.value), []);

  const submit = useCallback((event: FormEvent) => {
    event.preventDefault();
    usernameEntered(username);
    authenticate({variables: {username, password}});
  }, [authenticate, usernameEntered, username, password]);

  useEffect(() => {
    if (token) {
      tokenReceived(token);
    }
  }, [token, tokenReceived]);

  const loginForm = (
    <form onSubmit={submit}>
      <div className="login__form">
        <IonList className="ion-padding-horizontal">
          <IonItem className="ion-no-padding">
            <IonLabel color="light" position="floating"><FormattedMessage id="Label.Username"/></IonLabel>
            <IonInput name="username" value={username} onIonChange={usernameChanged}/>
            <IonIcon icon={person} slot="start"/>
          </IonItem>
          <IonItem className="ion-no-padding">
            <IonLabel color="light" position="floating"><FormattedMessage id="Label.Password"/></IonLabel>
            <IonInput type="password" name="password" value={password} onIonChange={passwordChanged}/>
            <IonIcon icon={key} slot="start"/>
          </IonItem>
        </IonList>
        {error ? (
          <div className="ion-margin-top">
            <IonNote color="danger">
              <FormattedMessage id="Label.AuthenticationError"/>
            </IonNote>
          </div>
        ) : null}
      </div>
      <div className="login__button ion-padding-horizontal">
        <input type="submit" className="shadow-dom-not-submitting-workaround-button" disabled={!username || !password}/>
        <IonButton
          type="submit"
          color="primary"
          expand="full"
          class="ion-margin-vertical"
          disabled={!username || !password}
        >
          <FormattedMessage id="Label.Login"/>
        </IonButton>
      </div>
    </form>
  );

  return token ? <Redirect to="/"/> : loginForm;
});
