import {gql} from 'apollo-boost';

export const getCategorySelectQuery = gql`
    query getCategorySelect{
        categories {
            id
            name
        }
    }
`;
