import {
  createDownloadCartQueueItem, createDownloadCategoriesQueueItem, createDownloadFavoritesQueueItem, createDownloadOrdersQueueItem, createDownloadProductsQueueItem,
} from '../../Service/Queue/Command/QueueItemFactory';
import {QueueGateway} from '../../Service/Queue/QueueGateway';
import {QueueRunner} from '../../Service/Queue/QueueRunner';
import {AppType} from '../App/action';
import {AuthenticationType} from '../Authentication/action';
import {Middleware} from '../index';
import {QueueType, refreshData} from './action';

export class QueueMiddleware {
  public constructor(private queueRunner: QueueRunner, private queueGateway: QueueGateway) {
  }

  public middleware: Middleware = store => next => action => {
    next(action);

    switch (action.type) {
      case AuthenticationType.Authenticated:
      case AppType.Initialized:
        if (store.getState().authentication.token !== undefined) {
          store.dispatch(refreshData());
          if (!this.queueRunner.isRunning()) {
            this.queueRunner.start(1);
          }
        }
        return;
      case AuthenticationType.LoggedOut:
        if (this.queueRunner.isRunning()) {
          this.queueRunner.stop();
        }
        return;
      case QueueType.RefreshData:
        this.queueGateway.add(createDownloadOrdersQueueItem());
        this.queueGateway.add(createDownloadCategoriesQueueItem());
        this.queueGateway.add(createDownloadProductsQueueItem());
        this.queueGateway.add(createDownloadFavoritesQueueItem());
        this.queueGateway.add(createDownloadCartQueueItem());
    }
  };
}
