import {
  IonCheckbox, IonItem, IonLabel, IonList,
} from '@ionic/react';
import React, {memo, useCallback} from 'react';
import {FormattedMessage} from 'react-intl';
import {FormFieldOption} from '../../../Model/Form/FormFieldOption';
import {FormFieldProps} from '../../../Model/Form/FormFieldProps';
import {FormValue} from '../../../Model/Form/FormValue';
import {RequiredComponent} from '../Layout/Required';

type PrimitiveType = 'string' | 'number';

interface Props extends FormFieldProps {
  options: FormFieldOption[];
  type: PrimitiveType;
  value: FormValue;
}

const convertValueToExpectedType = (expectedType: PrimitiveType, value: number | string): number | string => {
  if (typeof (value) === expectedType) {
    return value;
  }
  if (expectedType === 'number') {
    const parsed = parseInt(value as string, 10);
    if (!isNaN(parsed)) {
      return parsed;
    }
  }
  if (expectedType === 'string') {
    return value.toString();
  }

  return value;
};

const getNewValue = (type: PrimitiveType, value: FormValue, event: { detail: { checked: boolean; value: string | number } }): FormValue => {
  if (!Array.isArray(value)) {
    value = [];
  }

  const optionValue = convertValueToExpectedType(type, event.detail.value);
  const newValue = value.filter(val => val !== optionValue);
  if (!event.detail.checked) {
    return newValue;
  }
  newValue.push(optionValue);
  return newValue;
};

const isChecked = (value: FormValue, option: FormFieldOption): boolean => {
  if (!Array.isArray(value)) {
    return false;
  }
  return value.includes(option.key);
};

export const CheckboxGroup: React.FC<Props> = memo(({
  name, value, options, onChange, required, type,
}) => {
  const onCheck = useCallback(e => onChange(getNewValue(type, value, e)), [onChange, type, value]);
  const checkboxes = options.map(option => (
    <IonItem key={option.key}>
      <IonCheckbox slot="start" name={name} value={option.key} onIonChange={onCheck} checked={isChecked(value, option)}/>
      <IonLabel>{option.label}</IonLabel>
    </IonItem>
  ));

  return (
    <IonList>
      <IonLabel>
        <FormattedMessage id={`Admin.Form.Field.${name}.Label`}/>
        <RequiredComponent required={required}/>
      </IonLabel>
      {checkboxes}
    </IonList>
  );
});
