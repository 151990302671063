import {gql} from 'apollo-boost';

export const getOrderDetailQuery = gql`
    query GetOrderDetailQuery($id: Int!) {
        order(id: $id){
            id
            deliveryDate
            orderDate
            remark
            uuid
            user{
                id
                name
                email
                phoneNumber
                organisation {
                    id
                    name
                }
            }
            orderLines {
                id
                amount
                productName
                categoryName
            }
        }
    }
`;
