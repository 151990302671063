import {Category} from '../../Model/Category';

export enum CategoryType {
  CategoriesLoaded = 'categoriesLoaded',
}

export interface CategoriesLoadedAction {
  categories: Category[];
  type: CategoryType.CategoriesLoaded;
}

export const categoriesLoaded = (categories: Category[]): CategoriesLoadedAction => ({
  type: CategoryType.CategoriesLoaded,
  categories,
});

export type CategoryActions =
    | CategoriesLoadedAction;
