import {IonIcon, IonItem, IonItemOptions, IonItemSliding} from '@ionic/react';
import {closeCircleOutline} from 'ionicons/icons';
import React, {memo, ReactNode, useRef} from 'react';
import {useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {CartItem} from '../../Model/CartItem';
import {Product} from '../../Model/Product';
import {RootState} from '../../Store';
import {useToast} from '../../Toaster/Hook/UseToast';
import {FavoriteIconComponent} from '../Favorites/FavoriteIconComponent';
import {ProductQuantityChanger} from '../ProductQuantityChanger/ProductQuantityChanger';
import {RemoveProductIconComponent} from '../RemoveProduct/RemoveProductIconComponent';

interface Props {
  product: Product;
}

export const ProductListItemComponent: React.FC<Props> = memo(({product}) => {
  const cartItem = useSelector<RootState, CartItem | undefined>(state => state.cart.cartItems.find(item => item.productId === product.id));
  const amountInCart = cartItem ? cartItem.amount : 0;
  const slidingIonItemRef = useRef(null);
  const {formatMessage} = useIntl();
  const showToast = useToast();
  let cta: ReactNode;
  if (product.stock !== 0) {
    cta = <ProductQuantityChanger amountInCart={amountInCart} productId={product.id}/>;
  } else {
    cta = (
      <div
        role="button"
        className="product__not-available"
        onClick={() => showToast(formatMessage({id: 'Label.ProductNotAvailable'}), 1000)}
      >
        <IonIcon icon={closeCircleOutline}/>
      </div>
    );
  }

  // @todo refactor code to resolve technical depth (eslint)
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
  const closeItemSliding = () => (slidingIonItemRef.current as any)?.close();

  return (
    <IonItemSliding ref={slidingIonItemRef}>
      <IonItemOptions side="start" className="product__item-options">
        {amountInCart >= 1 && <RemoveProductIconComponent productId={product.id} closeParent={closeItemSliding}/>}
        <FavoriteIconComponent productId={product.id} closeParent={closeItemSliding}/>
      </IonItemOptions>
      <IonItem className="ion-no-padding">
        <div className="product">
          <div className="product__specs">
            <div className="product__name">
              {product.name}
            </div>
          </div>
          {cta}
        </div>
      </IonItem>
    </IonItemSliding>
  );
});
