import {DateTime} from 'luxon';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {CartItem} from '../Model/CartItem';
import {CreateOrder} from '../Model/CreateOrder';
import {Order} from '../Model/Order';
import {OrderUser} from '../Model/OrderUser';
import {Product} from '../Model/Product';
import {createUploadOrderQueueItem} from '../Service/Queue/Command/QueueItemFactory';
import {ServiceContainer} from '../Service/ServiceContainer/ServiceContainer';
import {RootState} from '../Store';
import {orderPlaced} from '../Store/Order/action';
import {useCartProducts} from './UseCartProducts';

const {queueGateway} = ServiceContainer;

const getOrder = (cart: CartItem[], cartProducts: Product[], user: OrderUser, deliveryDate: string, remark: string, uniqueId: string): Order => {
  const orderLines = cart.map(({amount, productId}) => ({amount, productName: cartProducts.find(({id}) => id === productId)!.name}));
  return {
    deliveryDate,
    orderDate: DateTime.local().toString(),
    orderLines,
    remark,
    user,
    uuid: uniqueId,
  };
};

const getCreateOrder = (cart: CartItem[], cartProducts: Product[], user: OrderUser, deliveryDate: string, remark: string, uniqueId: string): CreateOrder => {
  const orderLines = cart.map(({amount, productId}) => ({amount, productId}));
  return {
    deliveryDate,
    orderDate: DateTime.local().toString(),
    orderLines,
    remark,
    user,
    uuid: uniqueId,
  };
};

export const usePlaceOrder = (deliveryDate: string, remark: string): [boolean, () => void] => {
  const cartProducts = useCartProducts();
  const [hasOrdered, setHasOrdered] = useState<boolean>(false);
  const cart = useSelector<RootState, CartItem[]>(state => state.cart.cartItems);
  const user = useSelector<RootState, OrderUser>(state => state.authentication.decodedToken!.user);

  const dispatch = useDispatch();

  const placeOrder = () => {
    const uniqueId = uuidv4();
    dispatch(orderPlaced(getOrder(cart, cartProducts, user, deliveryDate, remark, uniqueId)));
    queueGateway.add(createUploadOrderQueueItem(getCreateOrder(cart, cartProducts, user, deliveryDate, remark, uniqueId)));
    setHasOrdered(true);
  };
  return [hasOrdered, placeOrder];
};
