import {gql} from 'apollo-boost';

export const getOrganisationEditQuery = gql`
    query getOrganisationEdit($id: Int!) {
        organisation(id: $id){
            id
            name
            categories {
                id
            }
        }
    }
`;
