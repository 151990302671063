import hash from 'object-hash';
import {CreateOrder} from '../../../Model/CreateOrder';
import {CommandTypes} from '../../../Model/Queue/CommandType';
import {QueueItem} from '../../../Model/Queue/QueueItem';
import {QueueStatus} from '../../../Model/Queue/QueueStatus';

const enrichId = (item: Omit<QueueItem, 'id'>): QueueItem => ({...item, id: hash(item)});

export const createDownloadCartQueueItem = (priority = 10): QueueItem => enrichId({
  priority,
  status: QueueStatus.Queued,
  commandType: CommandTypes.DownloadCart,
});

export const createDownloadCategoriesQueueItem = (priority = 10): QueueItem => enrichId({
  priority,
  status: QueueStatus.Queued,
  commandType: CommandTypes.DownloadCategories,
});

export const createDownloadFavoritesQueueItem = (priority = 10): QueueItem => enrichId({
  priority,
  status: QueueStatus.Queued,
  commandType: CommandTypes.DownloadFavorites,
});

export const createDownloadOrdersQueueItem = (priority = 10): QueueItem => enrichId({
  priority,
  status: QueueStatus.Queued,
  commandType: CommandTypes.DownloadOrders,
});

export const createDownloadProductsQueueItem = (priority = 10): QueueItem => enrichId({
  priority,
  status: QueueStatus.Queued,
  commandType: CommandTypes.DownloadProducts,
});

export const createUploadCartQueueItem = (priority = 20): QueueItem => enrichId({
  priority,
  status: QueueStatus.Queued,
  commandType: CommandTypes.UploadCart,
});

export const createUploadFavoritesQueueItem = (priority = 20): QueueItem => enrichId({
  priority,
  status: QueueStatus.Queued,
  commandType: CommandTypes.UploadFavorites,
});

export const createUploadOrderQueueItem = (order: CreateOrder, priority = 30): QueueItem => enrichId({
  priority,
  status: QueueStatus.Queued,
  commandType: CommandTypes.UploadOrder,
  data: order,
});
