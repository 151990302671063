import {RootState, Store} from '../../Store';
import {Action} from '../../Store/action';

export class StoreGateway {
  public constructor(private store?: Store) {
  }

  public dispatch(action: Action) {
    this.getStore().dispatch(action);
  }

  public getState(): RootState {
    return this.getStore().getState();
  }

  public getStore(): Store {
    if (typeof this.store !== 'undefined') {
      return this.store;
    }

    throw Error('Redux store not available');
  }

  public setStore(store: Store) {
    this.store = store;
  }
}
