import {Config} from '../Model/Environment/Config';

export const configProd: Config = {
  serverUrl: 'https://api.groentehal.nl',
  publicKey: '-----BEGIN CERTIFICATE-----\n'
    + 'MIIFujCCA6ICCQDk3t4GQ8LZoTANBgkqhkiG9w0BAQsFADCBnjELMAkGA1UEBhMC\n'
    + 'TkwxEDAOBgNVBAgMB1plZWxhbmQxEzARBgNVBAcMCk1pZGRlbGJ1cmcxHjAcBgNV\n'
    + 'BAoMFUdyb2VudGVoYWwgTWlkZGVsYnVyZzElMCMGA1UEAwwcbmwuZ3JvZW50ZWhh\n'
    + 'bC5hcHAucHJvZHVjdGlvbjEhMB8GCSqGSIb3DQEJARYSaW5mb0Bncm9lbnRlaGFs\n'
    + 'Lm5sMB4XDTIwMDMwNjE0MTM0MFoXDTIwMDQwNTE0MTM0MFowgZ4xCzAJBgNVBAYT\n'
    + 'Ak5MMRAwDgYDVQQIDAdaZWVsYW5kMRMwEQYDVQQHDApNaWRkZWxidXJnMR4wHAYD\n'
    + 'VQQKDBVHcm9lbnRlaGFsIE1pZGRlbGJ1cmcxJTAjBgNVBAMMHG5sLmdyb2VudGVo\n'
    + 'YWwuYXBwLnByb2R1Y3Rpb24xITAfBgkqhkiG9w0BCQEWEmluZm9AZ3JvZW50ZWhh\n'
    + 'bC5ubDCCAiIwDQYJKoZIhvcNAQEBBQADggIPADCCAgoCggIBALXgUU60OmUDtUDe\n'
    + 'vlEB81veBKVttEXHL0HuilxUsfFOcT+8VRxqUmV9obmMVrh5peZaUj4+YvYGGsXN\n'
    + 'ItkEbGgK5OG9hRORkRVQmtqQ2xn9ab5+wsyQ0/qmYC2Wer7v+g/1+pyTbmKP5DxT\n'
    + 'swmV/Y/DyNRKjUTOBKr7XFZXqYPjkqPWT40Wpbr/6uG8jdiRGjEKHyRc7WIQRBjt\n'
    + 'nsRpN0cH5BDhb2PsYc26E8HOKiADCt10aLcOGV5ev6sV1/cQiJx2ynI99+hvRCOr\n'
    + 'PONnfKvHOEdIxtD4mgKFVwM6WsH3VbYoVj+JOk386zo4viiirmlLdai3HtbTiSET\n'
    + '+0usimlKdK+2o8IpMqGv0M5ZcU8ZAO7AdII0vSSonPRFqkrHHfTOtwnp4xVZmFLt\n'
    + 'NLEwcg498TLgM2Uc/cvugdyVG4W8ZR8NcC7fOMvPIJ7NA6zBQo0n1rt4SILTmC/Y\n'
    + 'mTPqBxZof7JytGHXARmb9+RmZUUMnc0D27q7vz5TLkVK5UfL4qkkCVaGLLpXrpke\n'
    + 'ceZJrxdRF/RIrDQBDMo9NnfugzkNYHyfqNNuJoVJPYplWAAscZT9IOdWwH5ckCAg\n'
    + 'EAWFkGqhMca6Oih0TcRISdWUqVJvFe61lY8oM07s+B5v3+qbxoh5jDf/IdOsrP15\n'
    + 'TxImpgUYxDv0nVP/wQ0tDe7ivZGRAgMBAAEwDQYJKoZIhvcNAQELBQADggIBAG0Y\n'
    + 'mSBCOJBADbQ0FZGwVZsVcSl5g9YLIwh4PNsbpmEQRQt3Ol7q4s50qJw07XRDTnLV\n'
    + 'G/XsxkU2GE5CsdrMaKvMsWN1xkERy91VHur7LHqZBglUrN/m9OtlS3Pzs4wa9Mio\n'
    + 'UeBLzfZCd9ChoUwOzaQ5/KT9MUBsQQj2AaGcRrip+m+GDx+jdJl72kw/B4z281Of\n'
    + 'GUHBb7ro1WCS3Q6MNZ2iY8CcKm1Z/hflHlAlyTM1kV3wj1D75lqddXYgWtRFup6L\n'
    + 'G43Ju4OQt+cpdiBbKvjeGQJzeNilzRBu7Ibod6GQKeVmv/fMH13ph38dJGNbK23p\n'
    + 'wVCV40vM5SsV4lE7LRCgucvxr1FaHEgaKtTTaGwj9gbze8BPll7VS4smdb+ZnAC8\n'
    + 'LH38+1PREuUvwFc7TDgjV84ioXdwgMvCvlmtdUvKhVylYKVfR6ZICQkW127Bp7iv\n'
    + 'A867U1+dq8XwXok241rC27+xpcGoVus8Qg3q8iD9pCBGzbcQPjNhnPgoJOpwfuRE\n'
    + 'AycLN+2TS69ChnLPy9uFepyL/9/5uZmNnyTo+hAFzjOyt8WhF8iFvh/kiVdY+dnA\n'
    + '0ULjFI74GbofcNuET21ArVNLvmbntsagtkRHsxbZmYTejkO3AmAAdh5hiqpnR0+t\n'
    + 'AwJ56eTefdj085nJwPq3tOCKADbqxGvtMkJIoxoU\n'
    + '-----END CERTIFICATE-----',
};
