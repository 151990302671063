import {IonAlert, IonIcon} from '@ionic/react';
import {keypadOutline} from 'ionicons/icons';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {ServiceContainer} from '../../Service/ServiceContainer/ServiceContainer';
import {quantitySet} from '../../Store/Cart/action';

interface Props {
  productId: number;
  quantity: number;
}

interface QuantityInput {
  quantity: number;
}

const {translator} = ServiceContainer;

const hasFraction = (n: number) => ((n - Math.floor(n)) !== 0);

export const ManualQuantityComponent: React.FC<Props> = ({productId, quantity}) => {
  const dispatch = useDispatch();

  const [showManualQuantity, setShowManualQuantity] = useState(false);

  const toggleMQ = () => setShowManualQuantity(!showManualQuantity);
  const updateQuantity = (input: QuantityInput) => {
    if (hasFraction(input.quantity)) {
      return false;
    }
    return dispatch(quantitySet(productId, Number(input.quantity)));
  };

  return (
    <>
      <IonAlert
        isOpen={showManualQuantity}
        onDidDismiss={toggleMQ}
        header={translator.translate('manualQuantity.title')}
        inputs={[{name: 'quantity', type: 'number', value: ''}]}
        buttons={[{
          text: translator.translate('manualQuantity.cancel'),
          role: 'cancel',
          cssClass: 'secondary',
        }, {text: translator.translate('manualQuantity.ok'), handler: updateQuantity}]}
      />
      <div role="button" className="manual-quantity-selector" onClick={toggleMQ}>
        <IonIcon icon={keypadOutline}/>
        {quantity}
      </div>
    </>
  );
};
