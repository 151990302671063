import React, {memo} from 'react';
import DataTable, {IDataTableColumn, IDataTablePaginationOptions} from 'react-data-table-component';
import {SearchBarComponent} from '../../../Component/Search/SearchBarComponent';
import {Filter} from '../../../Service/Filter/Filter';
import {ServiceContainer} from '../../../Service/ServiceContainer/ServiceContainer';

interface Props {
  columns: Array<IDataTableColumn<any>>;
  data: any[];
}

const {translator} = ServiceContainer;
const filter = new Filter();
const paginationOptions: IDataTablePaginationOptions = {
  rowsPerPageText: translator.translate('Admin.Pagination.ItemsPerPage'),
  rangeSeparatorText: translator.translate('Admin.Pagination.RangeSeparator'),
};

export const EntityTable: React.FC<Props> = memo(({data, columns}) => {
  const [filterText, setFilterText] = React.useState('');
  const filteredItems = filter.filter(data, filterText, true);

  return (
    <DataTable
      columns={columns}
      pagination
      persistTableHead
      data={filteredItems}
      subHeader
      noDataComponent={null}
      paginationComponentOptions={paginationOptions}
      subHeaderComponent={<SearchBarComponent minCharacters={0} onInput={setFilterText}/>}
    />
  );
});
