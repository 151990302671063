import {TypeKind} from 'graphql';
import {UnknownFieldTypeError} from '../../Error/UnknownFieldTypeError';
import {GraphField} from '../../Model/Form/GraphField';
import {Mutation} from '../../Model/Form/Mutation';

const getType = (field: GraphField): string => {
  switch (true) {
    case field.kind === TypeKind.NON_NULL:
      return `${getType(field.ofType)}!`;
    case field.kind === TypeKind.LIST:
      return `[${getType(field.ofType)}]`;
    case field.kind === TypeKind.SCALAR:
    case field.kind === TypeKind.ENUM:
      return field.name;
  }

  throw new UnknownFieldTypeError();
};

export const createMutation = (mutation: Mutation): string => {
  const fields = mutation.args[0].type.ofType.inputFields;
  const mutationHeaderFields = fields.map(field => `$${field.name}: ${getType(field.type)}`);
  const callHeaderFields = fields.map(field => `${field.name}: $${field.name}`);
  return `mutation RunMutation (${mutationHeaderFields.join(', ')}) {
        ${mutation.name}(${mutation.args[0].name}: {${callHeaderFields.join(', ')}}) {
            id
        }
    }
    `;
};
