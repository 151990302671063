import {Action} from '../action';
import {AuthenticationType} from './action';
import {AuthenticationState} from './state';

const initialState: AuthenticationState = {token: undefined, decodedToken: undefined};

export const authenticationReducer = (state = initialState, action: Action): AuthenticationState => {
  switch (action.type) {
    case AuthenticationType.TokenReceived:
      return {...state, token: action.token};
    case AuthenticationType.Authenticated:
      return {...state, decodedToken: action.decodedToken};
    case AuthenticationType.LoggedOut:
      return {...state, token: undefined};
    default:
      return state;
  }
};
