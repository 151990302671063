import {RefresherEventDetail} from '@ionic/core';
import {IonRefresher, IonRefresherContent} from '@ionic/react';
import React, {useCallback} from 'react';
import {useDispatch, useStore} from 'react-redux';
import {QueueStatus} from '../../Model/Queue/QueueStatus';
import {RootState} from '../../Store';
import {refreshData} from '../../Store/Queue/action';

const loadingStatuses = [QueueStatus.Queued, QueueStatus.Retry, QueueStatus.InProgress];

export const QueueRefresher: React.FC = () => {
  const dispatch = useDispatch();
  const store = useStore<RootState>();
  const doRefresh = useCallback((event: CustomEvent<RefresherEventDetail>) => {
    dispatch(refreshData());
    const unsubscribe = store.subscribe(() => {
      const queueSize = store.getState().queue.filter(({status}) => loadingStatuses.includes(status)).length;
      if (queueSize === 0) {
        event.detail.complete();
        unsubscribe();
      }
    });
  }, [dispatch, store]);

  return (
    <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
      <IonRefresherContent/>
    </IonRefresher>
  );
};
