import {IonCol, IonGrid, IonRow} from '@ionic/react';
import React, {memo} from 'react';
import {FormattedMessage} from 'react-intl';
import {formatOrderNumber} from '../../../Utils/OrderNumberFormatter';
import {GetOrderDetailQuery_order} from '../../GraphQL/Query/types/GetOrderDetailQuery';
import {OrderDetailMetadataComponent} from './OrderDetailMetadataComponent';
import {OrderLinesComponent} from './OrderLinesComponent';

interface Props {
  order: GetOrderDetailQuery_order;
}

export const OrderDetailComponent: React.FC<Props> = memo(({order}) => (
  <IonGrid>
    <IonRow>
      <IonCol>
        <h1><FormattedMessage id="Admin.order.Header" values={{name: order.user.name, orderNumber: formatOrderNumber(order.id)}}/></h1>
      </IonCol>
    </IonRow>
    <IonRow>
      <IonCol size="8">
        <OrderLinesComponent orderLines={order.orderLines}/>
      </IonCol>
      <IonCol>
        <OrderDetailMetadataComponent order={order}/>
      </IonCol>
    </IonRow>
  </IonGrid>
));
