import {categoriesQuery} from '../../../GraphQL/Query/categoriesQuery';
import {CategoriesQuery} from '../../../GraphQL/Query/types/CategoriesQuery';
import {QueueCommand} from '../../../Model/Queue/QueueCommand';
import {QueueStatus} from '../../../Model/Queue/QueueStatus';
import {categoriesLoaded} from '../../../Store/Category/action';
import {GraphQlClient} from '../../GraphQl/GraphQlClient';
import {StoreGateway} from '../../Store/StoreGateway';

export class DownloadCategoriesCommand implements QueueCommand {
  public constructor(private graphQlClient: GraphQlClient, private storeGateway: StoreGateway) {
  }

  public async execute(): Promise<QueueStatus> {
    try {
      const result = await this.graphQlClient.getClient().query<CategoriesQuery>({query: categoriesQuery, fetchPolicy: 'network-only'});
      if (!result) {
        return QueueStatus.Failed;
      }
      this.storeGateway.dispatch(categoriesLoaded(result.data.categories));
      return QueueStatus.Done;
    } catch (e) {
      console.error(e);
    }
    return QueueStatus.Retry;
  }
}
