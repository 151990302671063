import {IonCard, IonCardHeader, IonCardTitle} from '@ionic/react';
import React, {memo} from 'react';
import {FormattedMessage} from 'react-intl';
import {entityTypes} from '../../Config/EntityTypes';

export const AdminListComponent: React.FC = memo(() => {
  const rows = entityTypes.map(entityType => (
    <IonCard key={entityType} routerLink={`/admin/${entityType}`}>
      <IonCardHeader>
        <IonCardTitle><FormattedMessage id={`Admin.${entityType}.Label`}/></IonCardTitle>
      </IonCardHeader>
    </IonCard>
  ));
  return <>{rows}</>;
});
