import {IonAlert, IonButton, IonIcon} from '@ionic/react';
import {trash} from 'ionicons/icons';
import React, {memo, useState} from 'react';
import {useIntl} from 'react-intl';

interface Props {
  canDelete: boolean;
  entityType: string;
  onDelete: () => void;
}

export const DeleteButton: React.FC<Props> = memo(({canDelete, onDelete, entityType}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const {formatMessage} = useIntl();

  if (!canDelete) {
    return null;
  }
  const onDismiss = () => setShowConfirmation(false);
  const onClick = () => setShowConfirmation(true);
  const buttons = [
    {
      text: formatMessage({id: 'Admin.Delete.Ok'}),
      handler: () => {
        onDelete();
      },
    },
    {
      text: formatMessage({id: 'Admin.Delete.Cancel'}),
      role: 'cancel',
      cssClass: 'secondary',
    },
  ];
  return (
    <IonButton onClick={onClick}>
      <IonIcon icon={trash}/>
      <IonAlert
        isOpen={showConfirmation}
        onDidDismiss={onDismiss}
        header={formatMessage({id: `Admin.${entityType}.Delete`})}
        buttons={buttons}
      />
    </IonButton>
  );
});
