import {VerifyOptions} from 'jsonwebtoken';
import {InvalidTokenError} from '../Error/InvalidTokenError';
import {Token} from '../Model/Authentication/Token';
import {ConfigGateway} from '../Service/Config/ConfigGateway';
import {MissingTokenError} from '../Error/MissingTokenError';

type Verify = (token: string, secretOrPublicKey: string | Buffer, options?: VerifyOptions) => any | string;

export class JwtDecoder {
  public constructor(private configGateway: ConfigGateway, private verifyFunction: Verify) {
  }

  public decodeToken(token: string): Token {
    if (!token) {
      throw new MissingTokenError();
    }

    try {
      return this.verifyFunction(token, this.configGateway.getConfig().publicKey, {algorithms: ['RS256']}) as Token;
    } catch (e) {
      throw new InvalidTokenError();
    }
  }
}
