import {Mutex} from 'async-mutex';
import {EmptyQueueError} from '../../Error/EmptyQueueError';
import {QueueItem} from '../../Model/Queue/QueueItem';
import {QueueStatus} from '../../Model/Queue/QueueStatus';
import {queueItemAdded, queueItemUpdated} from '../../Store/Queue/action';
import {StoreGateway} from '../Store/StoreGateway';

export class QueueGateway {
  public constructor(private storeGateway: StoreGateway, private mutex: Mutex) {
  }

  public add(item: QueueItem): void {
    this.storeGateway.dispatch(queueItemAdded(item));
  }

  public async getNext(): Promise<QueueItem> {
    const release = await this.mutex.acquire();

    const highestPrioQueueItem = this.getHighestPrioQueueItem();
    if (!highestPrioQueueItem) {
      release();
      throw new EmptyQueueError();
    }
    this.setStatus(highestPrioQueueItem.id, QueueStatus.InProgress);
    highestPrioQueueItem.status = QueueStatus.InProgress;
    release();
    return highestPrioQueueItem;
  }

  public setStatus(id: string, status: QueueStatus): void {
    this.storeGateway.dispatch(queueItemUpdated(id, status));
  }

  private getHighestPrioQueueItem(): QueueItem | null {
    const queueItems = this.storeGateway.getState().queue.filter(queueItem => queueItem.status === QueueStatus.Queued);
    return queueItems.reduce((accumulator: QueueItem | null, queueItem: QueueItem) => {
      if (!accumulator) {
        return queueItem;
      }
      if (queueItem.priority > accumulator.priority) {
        return queueItem;
      }
      return accumulator;
    }, null);
  }
}
