import {productsQuery} from '../../../GraphQL/Query/productsQuery';
import {ProductsQuery} from '../../../GraphQL/Query/types/ProductsQuery';
import {QueueCommand} from '../../../Model/Queue/QueueCommand';
import {QueueStatus} from '../../../Model/Queue/QueueStatus';
import {productsLoaded} from '../../../Store/Product/action';
import {GraphQlClient} from '../../GraphQl/GraphQlClient';
import {StoreGateway} from '../../Store/StoreGateway';

export class DownloadProductsCommand implements QueueCommand {
  public constructor(private graphQlClient: GraphQlClient, private storeGateway: StoreGateway) {
  }

  public async execute(): Promise<QueueStatus> {
    try {
      const result = await this.graphQlClient.getClient().query<ProductsQuery>({query: productsQuery, fetchPolicy: 'network-only'});
      if (!result) {
        return QueueStatus.Failed;
      }
      this.storeGateway.dispatch(productsLoaded(result.data.products));
      return QueueStatus.Done;
    } catch (e) {
      console.error(e);
    }
    return QueueStatus.Retry;
  }
}
