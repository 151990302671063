import {QueueItem} from '../../Model/Queue/QueueItem';
import {QueueStatus} from '../../Model/Queue/QueueStatus';
import {Sleep} from '../../Utils/Sleep';
import {CommandFactory} from './Command/CommandFactory';
import {QueueGateway} from './QueueGateway';

export class QueueItemConsumer {
  public constructor(private gateway: QueueGateway, private commandFactory: CommandFactory, private sleep: Sleep) {
  }

  public async processCommand(item: QueueItem): Promise<void> {
    try {
      const command = this.commandFactory.createFromQueueItem(item);
      let status = await command.execute();
      if (status === QueueStatus.Retry) {
        status = QueueStatus.Queued;
        this.gateway.setStatus(item.id, status);
        await this.sleep(5000);
        return;
      }
      this.gateway.setStatus(item.id, status);
    } catch (e) {
      this.gateway.setStatus(item.id, QueueStatus.Failed);
    }
  }
}
