import {
  IonAvatar, IonCard, IonIcon, IonItem, IonLabel, IonList,
} from '@ionic/react';
import {business, cart, logIn} from 'ionicons/icons';
import React, {memo} from 'react';
import {FormattedMessage} from 'react-intl';
import {DateComponent} from '../../../Component/Date/DateComponent';
import {GetOrderDetailQuery_order} from '../../GraphQL/Query/types/GetOrderDetailQuery';

interface Props {
  order: GetOrderDetailQuery_order;
}

export const OrderDetailMetadataComponent: React.FC<Props> = memo<Props>(({order}) => (
  <IonCard>
    <IonList className="ion-no-padding">
      <IonItem>
        <IonAvatar slot="start">
          <IonIcon size="large" icon={business}/>
        </IonAvatar>
        <IonLabel>
          <h2>{order.user.organisation.name}</h2>
          <p>{order.user.phoneNumber}</p>
          <p>{order.user.email}</p>
        </IonLabel>
      </IonItem>
      <IonItem>
        <IonAvatar slot="start">
          <IonIcon size="large" icon={cart}/>
        </IonAvatar>
        <IonLabel>
          <h2><FormattedMessage id="Admin.order.OrderDateLabel"/></h2>
          <p><DateComponent date={order.orderDate}/></p>
        </IonLabel>
      </IonItem>
      <IonItem>
        <IonAvatar slot="start">
          <IonIcon size="large" icon={logIn}/>
        </IonAvatar>
        <IonLabel>
          <h2><FormattedMessage id="Admin.order.DeliveryDateLabel"/></h2>
          <p><DateComponent date={order.deliveryDate}/></p>
        </IonLabel>
      </IonItem>
    </IonList>
  </IonCard>
));
