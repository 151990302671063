import {connect} from 'react-redux';
import {Dispatch, RootState} from '../../Store';
import {ToastList} from '../Component/ToastList';
import {Toast} from '../Model/Toast';
import {hideToast} from '../Store/action';

interface OwnProps {
}

type StateProps = OwnProps & {
  toasts: Toast[];
};

interface DispatchProps {
  hideToast: (id: number) => void;
}

export type ToastListProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => ({
  toasts: state.toasts,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  hideToast: (id: number) => {
    dispatch(hideToast(id));
  },
});

export const ToastListContainer = connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)(ToastList);
