import {ServiceContainer} from '../../../Service/ServiceContainer/ServiceContainer';
import {GraphField} from '../../Model/Form/GraphField';

const {translator} = ServiceContainer;

const getLabel = (fieldName: string, enumValue: string): string => {
  const translationPrefix = `Admin.Form.Field.${fieldName}.Value.`;
  if (translator.hasTranslation(translationPrefix + enumValue)) {
    return translator.translate(translationPrefix + enumValue);
  }
  return enumValue;
};

const getEnumValues = (field: GraphField): string[] => {
  if (field.enumValues) {
    return field.enumValues.map(enumValue => enumValue.name);
  }
  return getEnumValues(field);
};

export const extractEnumFormOptions = (fieldName: string, field: GraphField) => getEnumValues(field).map(enumValue => ({
  key: enumValue,
  label: getLabel(fieldName, enumValue),
}));
