import {
  IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar,
} from '@ionic/react';
import React, {memo} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {CartOverviewComponent} from '../Component/Cart/CartOverviewComponent';
import {ConfirmOrderButtonComponent} from '../Component/Cart/ConfirmOrderButtonComponent';

export const CartDetailScreen: React.FC = memo(() => {
  const {formatMessage} = useIntl();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton text={formatMessage({id: 'Label.Back'})} defaultHref="/categories"/>
          </IonButtons>
          <IonTitle><FormattedMessage id="Title.Cart"/></IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <CartOverviewComponent/>
        <ConfirmOrderButtonComponent/>
      </IonContent>
    </IonPage>
  );
});
