import jwt from 'jsonwebtoken';
import {connect} from 'react-redux';
import {App} from '../../Component/App/App';
import {RootState} from '../../Store';

interface OwnProps {
}

type StateProps = OwnProps & {
  authenticated: boolean;
};

interface DispatchProps {
}

export type AppContainerProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => ({
  // Note: This does not verify expiration.
  authenticated: !!jwt.decode(state.authentication.token!),
});

export const AppContainer = connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps)(App);
