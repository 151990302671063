import React, {memo} from 'react';
import {useSelector} from 'react-redux';
import {Product} from '../../Model/Product';
import {Filter} from '../../Service/Filter/Filter';
import {RootState} from '../../Store';
import {ProductListComponent} from '../ProductList/ProductListComponent';

interface Props {
  searchValue: string;
}

export const SearchResultComponent: React.FC<Props> = memo(({searchValue}) => {
  const products = useSelector<RootState, Product[]>(state => state.product);
  const filter = new Filter();

  return (
    <div className="custom-search__results">
      <ProductListComponent products={filter.filter(products, searchValue)} searchPage/>
    </div>
  );
});
