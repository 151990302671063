import {gql} from 'apollo-boost';

export const getCategoryListQuery = gql`
    query GetCategoryList {
        categories{
            id
            name
            priority
        }
    }
`;
