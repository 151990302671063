export enum AppType {
  Initialized = 'initialized',
}

export interface InitializedAction {
  type: AppType.Initialized;
}

export const initialized = (): InitializedAction => ({
  type: AppType.Initialized,
});

export type AppActions =
    | InitializedAction;
