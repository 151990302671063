import {DateTime} from 'luxon';

export const getFirstAvailableDeliveryDate = (): DateTime => {
  const maxTime = DateTime.local().set({
    hour: 5, minute: 0, second: 0, millisecond: 0,
  });
  const dayOffset = Number(DateTime.local().toISOTime() >= maxTime.toISOTime());

  return DateTime.local().plus({day: dayOffset});
};

export const getMaximumDeliveryDate = (): DateTime => DateTime.local().plus({years: 1});
