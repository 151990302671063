import {Mutex} from 'async-mutex';
import {verify} from 'jsonwebtoken';
import {configAcc} from '../../Config/config-acc';
import {configDev} from '../../Config/config-dev';
import {configProd} from '../../Config/config-prod';
import {configTest} from '../../Config/config-test';
import {StoreConfigurator} from '../../Store';
import {AuthenticationMiddleware} from '../../Store/Authentication/middleware';
import {CartMiddleware} from '../../Store/Cart/middleware';
import {EnvironmentMiddleware} from '../../Store/Environment/middleware';
import {FavoriteMiddleware} from '../../Store/Favorite/middleware';
import {PersistMiddleware} from '../../Store/Persist/middleware';
import {QueueMiddleware} from '../../Store/Queue/middleware';
import {JwtDecoder} from '../../Utils/JwtDecoder';
import {sleep} from '../../Utils/Sleep';
import {ConfigGateway} from '../Config/ConfigGateway';
import {GraphQlClient} from '../GraphQl/GraphQlClient';
import {CommandFactory} from '../Queue/Command/CommandFactory';
import {QueueGateway} from '../Queue/QueueGateway';
import {QueueItemConsumer} from '../Queue/QueueItemConsumer';
import {QueueRunner} from '../Queue/QueueRunner';
import {QueueWorker} from '../Queue/QueueWorker';
import {StoreGateway} from '../Store/StoreGateway';
import {Translator} from '../Translation/Translator';

const storeGateway = new StoreGateway();
const configGateway = new ConfigGateway(storeGateway, configDev, configTest, configAcc, configProd);
const translator = new Translator('nl');

const jwtDecoder = new JwtDecoder(configGateway, verify);
const mutex = new Mutex();
const graphQlClient = new GraphQlClient(configGateway, storeGateway);

const commandFactory = new CommandFactory(graphQlClient, storeGateway);
const queueGateway = new QueueGateway(storeGateway, mutex);
const queueItemConsumer = new QueueItemConsumer(queueGateway, commandFactory, sleep);
const queueWorker = new QueueWorker(queueGateway, queueItemConsumer);
const queueRunner = new QueueRunner(queueWorker, sleep, storeGateway);

const authenticationMiddleware = new AuthenticationMiddleware(jwtDecoder);
const cartMiddleware = new CartMiddleware(queueGateway);
const environmentMiddleware = new EnvironmentMiddleware();
const favoriteMiddleware = new FavoriteMiddleware(queueGateway);
const persistMiddleware = new PersistMiddleware();
const queueMiddleware = new QueueMiddleware(queueRunner, queueGateway);

const storeConfigurator = new StoreConfigurator([
  authenticationMiddleware.middleware,
  cartMiddleware.middleware,
  environmentMiddleware.middleware,
  favoriteMiddleware.middleware,
  persistMiddleware.middleware,
  queueMiddleware.middleware,
]);

export const ServiceContainer = ({
  graphQlClient,
  translator,
  storeGateway,
  storeConfigurator,
  configGateway,
  queueGateway,
});
