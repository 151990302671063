import {JwtDecoder} from '../../Utils/JwtDecoder';
import {Middleware, MiddlewareApi} from '../index';
import {authenticated, AuthenticationType, loggedOut} from './action';

export class AuthenticationMiddleware {
  public constructor(private jwtDecoder: JwtDecoder) {
  }

  public middleware: Middleware = store => next => action => {
    next(action);

    switch (action.type) {
      case AuthenticationType.TokenReceived:
        return this.tokenReceived(action.token, store);
    }

    return undefined;
  };

  private async tokenReceived(token: string, store: MiddlewareApi): Promise<void> {
    try {
      const model = this.jwtDecoder.decodeToken(token);
      const isNewUser = store.getState().authentication.decodedToken?.user.id !== model.user.id;
      store.dispatch(authenticated(model, isNewUser));
    } catch (e) {
      store.dispatch(loggedOut());
    }
  }
}
