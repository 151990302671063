import {
  IonItem, IonLabel, IonList, IonListHeader,
} from '@ionic/react';
import React, {memo} from 'react';
import {FormattedMessage} from 'react-intl';
import {GetOrderDetailQuery_order_orderLines} from '../../GraphQL/Query/types/GetOrderDetailQuery';
import {unique} from '../../Util/Unique';
import {OrderLinesCategoryDetailComponent} from './OrderLinesCategoryDetailComponent';

interface Props {
  orderLines: GetOrderDetailQuery_order_orderLines[];
}

export const OrderLinesComponent: React.FC<Props> = memo(({orderLines}) => {
  const categories = unique(orderLines.map(({categoryName}) => categoryName)).map(category => <OrderLinesCategoryDetailComponent key={category} category={category} orderLines={orderLines}/>);

  const total = orderLines.reduce((accumulator, orderLine) => accumulator + orderLine.amount, 0);
  return (
    <IonList>
      {categories}
      <IonItem>
        <IonListHeader className="ion-no-padding">
          <IonLabel>
            <FormattedMessage id="Admin.order.Total"/>
          </IonLabel>
        </IonListHeader>
        {total}
      </IonItem>
    </IonList>
  );
});
