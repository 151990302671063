import {IonLabel, IonSelect, IonSelectOption} from '@ionic/react';
import React, {memo, useCallback} from 'react';
import {FormattedMessage} from 'react-intl';
import {FormFieldOption} from '../../../Model/Form/FormFieldOption';
import {FormFieldProps} from '../../../Model/Form/FormFieldProps';
import {FormValue} from '../../../Model/Form/FormValue';
import {RequiredComponent} from '../Layout/Required';

interface Props extends FormFieldProps {
  multiple?: boolean;
  options: FormFieldOption[];
  value: FormValue;
}

const defaultAttributes = {
  pattern: 'text',
  type: 'text',
};

export const SelectField: React.FC<Props> = memo(({
  name, value, options, onChange, multiple = false, required, attributes = defaultAttributes,
}) => {
  const callback = useCallback(e => onChange((e.target).value), [onChange]);
  const selectOptions = options.map(option => <IonSelectOption key={option.key} value={option.key}>{option.label}</IonSelectOption>);

  return (
    <>
      <IonLabel position="floating">
        <FormattedMessage id={`Admin.Form.Field.${name}.Label`}/>
        <RequiredComponent required={required}/>
      </IonLabel>
      <IonSelect
        name={name}
        multiple={multiple}
        value={value}
        onIonChange={callback}
        {...attributes}
      >
        {selectOptions}
      </IonSelect>
    </>
  );
});
