import {Order} from '../../Model/Order';
import {AuthenticationType} from '../Authentication/action';
import {Action} from '../action';
import {OrderType} from './action';
import {OrderState} from './state';

const initialState: OrderState = [];

export const orderReducer = (state = initialState, action: Action): OrderState => {
  switch (action.type) {
    case OrderType.OrdersLoaded:
      return action.orders;
    case OrderType.OrderUpdated:
    case OrderType.OrderPlaced:
      const orders = state.filter(({uuid}) => uuid !== action.order.uuid);
      orders.push(action.order);
      orders.sort((a: Order, b: Order) => (a.deliveryDate < b.deliveryDate ? -1 : a.deliveryDate > b.deliveryDate ? 1 : 0));
      return orders;
    case AuthenticationType.Authenticated:
      return action.isNewUser ? initialState : state;
    default:
      return state;
  }
};
