import React, {memo} from 'react';
import {useSelector} from 'react-redux';
import {CartItem} from '../../Model/CartItem';
import {Product} from '../../Model/Product';
import {RootState} from '../../Store';
import {ErrorComponent} from '../Error/ErrorComponent';
import {ProductListItemComponent} from '../ProductList/ProductListItemComponent';

interface Props {
  cartItem: CartItem;
}

export const CartItemComponent: React.FC<Props> = memo(({cartItem}) => {
  const product = useSelector<RootState, Product | undefined>(state => state.product.find(({id}) => id === cartItem.productId));
  if (!product) {
    return <ErrorComponent/>;
  }
  return <ProductListItemComponent product={product}/>;
});
