import React, {memo, useCallback} from 'react';
import {FormattedMessage} from 'react-intl';
import AutoSizer from 'react-virtualized-auto-sizer';
import {VariableSizeList} from 'react-window';
import {Product} from '../../Model/Product';
import {ProductListItemComponent} from './ProductListItemComponent';

interface Props {
  products: Product[];
  searchPage?: boolean | undefined;
}

export const ProductListComponent: React.FC<Props> = memo(({products, searchPage}) => {
  const Row = useCallback((data: { index: number; style: any }) => (
    <div style={data.style}>
      <ProductListItemComponent product={products[data.index]} key={products[data.index].id}/>
    </div>
  ), [products]);

  // first item gets a larger height because of the required margin top
  const getItemSize = useCallback((index: number) => {
    if (!searchPage || index > 0) {
      return 81;
    }
    return 81 + 60;
  }, [searchPage]);

  const ProductList = useCallback((data: { height: number; width: number }) => (
    <VariableSizeList
      height={data.height}
      width={data.width}
      itemCount={products.length}
      itemSize={getItemSize}
      overscanCount={3}
    >
      {Row}
    </VariableSizeList>
  ), [getItemSize, products, Row]);

  return (
    <>
      <div className={searchPage ? 'product-list product-list--search-page' : 'product-list'}>
        {products.length === 0 && <div className="ion-padding-top ion-padding-bottom ion-text-center"><FormattedMessage id="Label.NoProducts"/></div>}
        <AutoSizer>
          {ProductList}
        </AutoSizer>
      </div>
    </>
  );
});
