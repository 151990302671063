import {Product} from '../../Model/Product';

export enum ProductType {
  ProductsLoaded = 'ProductsLoaded',
}

export interface ProductsLoadedAction {
  products: Product[];
  type: ProductType.ProductsLoaded;
}

export const productsLoaded = (products: Product[]): ProductsLoadedAction => ({
  type: ProductType.ProductsLoaded,
  products,
});

export type ProductActions =
    | ProductsLoadedAction;
