import React, {memo} from 'react';
import {SortFunction} from '../../../Utils/SortFunction';
import {AdminListPage} from '../../Component/Page/AdminListPage';
import {removeCategoryMutation} from '../../GraphQL/Mutation/removeCategoryMutation';
import {getCategoryListQuery} from '../../GraphQL/Query/getCategoryListQuery';
import {GetCategoryList_categories} from '../../GraphQL/Query/types/GetCategoryList';

const sort: SortFunction<GetCategoryList_categories> = (a, b) => b.priority - a.priority;

export const CategoryAdminListScreen: React.FC = memo(() => (
  <AdminListPage entityType="category" getQuery={getCategoryListQuery} deleteMutation={removeCategoryMutation} sortFunction={sort}/>
));
