import {
  IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonImg,
} from '@ionic/react';
import React, {memo, useCallback} from 'react';
import {FormattedMessage} from 'react-intl';
import {OnChange} from '../../../Model/Form/OnChange';
import {getBaseEncodedFile} from '../../../Service/Form/BaseEncodedFileReader';
import {RequiredComponent} from '../Layout/Required';

interface Props {
  name: string;
  onChange: OnChange;
  required: boolean;
  value: string | undefined | null;
}

export const ImageField: React.FC<Props> = memo(({
  name, value, onChange, required,
}) => {
  const onDelete = useCallback(() => onChange(null), [onChange]);
  const onImageSelect = useCallback(async e => {
    onChange(await getBaseEncodedFile(e.target.files[0]));
  }, [onChange]);
  return (
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>
          <FormattedMessage id={`Admin.Form.Field.${name}.Label`}/>
          <RequiredComponent required={required}/>
        </IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <input name={name} type="file" onChange={onImageSelect} accept="image/png, image/jpeg"/>
        {value && <IonImg src={value}/>}
        {value && <IonButton onClick={onDelete}><FormattedMessage id="Admin.Delete.Image"/></IonButton>}
      </IonCardContent>

    </IonCard>
  );
});
