import {gql} from 'apollo-boost';

export const categoriesQuery = gql`
    query CategoriesQuery {
        categories {
            id
            name
            image
        }
    }
`;
