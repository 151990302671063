import {IonCard, IonCardContent, IonIcon, IonRippleEffect} from '@ionic/react';
import {checkmark, warning} from 'ionicons/icons';
import React, {memo} from 'react';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import {Order} from '../../Model/Order';
import {formatOrderNumber} from '../../Utils/OrderNumberFormatter';
import {DateComponent} from '../Date/DateComponent';

interface Props {
  order: Order;
  userId: number;
}

export const OrderListItemComponent: React.FC<Props> = memo(({order, userId}) => {
  const isOrderOwner = userId === order.user.id;
  return (
    <IonCard>
      <Link to={`/order/${order.uuid}`} className="link-as-block ion-activatable">
        <IonRippleEffect/>
        <IonCardContent>
          <div className="ion-margin-bottom">
            <strong className="order-list__title">
              <div className="order-list__date">
                {order.id && <IonIcon icon={checkmark} color="success" className="ion-margin-end"/>}
                {!order.id && <IonIcon icon={warning} color="warning" className="ion-margin-end"/>}
                <DateComponent date={order.deliveryDate}/>
              </div>
              {isOrderOwner && <div className="my-order-flag"><FormattedMessage id="Label.MyOrder"/></div>}
            </strong>
          </div>
          {order.id && <FormattedMessage id="Order.Status.Persisted" values={{order_number: formatOrderNumber(order.id)}}/>}
          {!order.id && <FormattedMessage id="Order.Status.Waiting"/>}
          <p className="order-list__link ion-padding-top" color="primary"><FormattedMessage id="Order.View"/></p>
        </IonCardContent>
      </Link>
    </IonCard>
  );
});
