import React, {memo} from 'react';
import {FormattedMessage} from 'react-intl';
import {Order} from '../../Model/Order';
import {DateComponent} from '../Date/DateComponent';
import {OrderLineDetailComponent} from './OrderLineDetailComponent';

interface Props {
  order: Order;
}

export const OrderDetailComponent: React.FC<Props> = memo(({order}) => {
  let remark = null;
  if (order.remark !== '') {
    remark = (
      <div>
        <span className="custom-list-header"><FormattedMessage id="Order.Remark"/></span>
        {order.remark}
      </div>
    );
  }

  return (
    <div className="order-summary">
      <div className="order-summary__info">
        <strong>
          <FormattedMessage id="Order.DeliveryDate"/>
          :
        </strong>
        {' '}
        <DateComponent date={order.deliveryDate}/>
        <br/>
        <strong>
          <FormattedMessage id="Order.Owner"/>
          :
        </strong>
        {' '}
        {order.user.name}
      </div>
      <div className="order-summary__list-headings">
        <span><FormattedMessage id="Label.Product"/></span>
        <span><FormattedMessage id="Label.Amount"/></span>
      </div>
      {order.orderLines.map(orderLine => <OrderLineDetailComponent key={orderLine.productName} orderLine={orderLine}/>)}
      {remark}
    </div>
  );
});
