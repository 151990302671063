import {IonIcon, IonItemOption} from '@ionic/react';
import {trash} from 'ionicons/icons';
import React from 'react';
import {useDispatch} from 'react-redux';
import {removedProductFromCart} from '../../Store/Cart/action';

interface Props {
  closeParent: () => void;
  productId: number;
}

export const RemoveProductIconComponent: React.FC<Props> = ({productId, closeParent}) => {
  const dispatch = useDispatch();

  const remove = () => {
    dispatch(removedProductFromCart(productId));
    closeParent();
  };

  return (
    <IonItemOption slot="icon-only" color="light" onClick={remove} className="product__remove">
      <IonIcon slot="icon-only" icon={trash}/>
    </IonItemOption>
  );
};
