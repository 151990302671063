import {EnvironmentEnum} from '../../Model/Environment/EnvironmentEnum';

export enum EnvironmentType {
  EnvironmentSelected = 'environmentSelected',
}

export interface EnvironmentSelectedAction {
  environment: EnvironmentEnum;
  type: EnvironmentType.EnvironmentSelected;
}

export const environmentSelected = (environment: EnvironmentEnum): EnvironmentSelectedAction => ({
  type: EnvironmentType.EnvironmentSelected,
  environment,
});

export type EnvironmentActions =
    | EnvironmentSelectedAction;
