import {CommandTypes} from '../../Model/Queue/CommandType';
import {QueueStatus} from '../../Model/Queue/QueueStatus';
import {createUploadCartQueueItem} from '../../Service/Queue/Command/QueueItemFactory';
import {QueueGateway} from '../../Service/Queue/QueueGateway';
import {Middleware} from '../index';
import {OrderType} from '../Order/action';
import {CartType} from './action';

export class CartMiddleware {
  public constructor(private queueGateway: QueueGateway) {
  }

  public middleware: Middleware = store => next => action => {
    next(action);

    switch (action.type) {
      case CartType.Increased:
      case CartType.Decreased:
      case CartType.RemovedProduct:
      case CartType.QuantitySet:
      case OrderType.OrderPlaced:
        if (!store.getState().queue.find(({status, commandType}) => commandType === CommandTypes.UploadCart && status === QueueStatus.Queued)) {
          this.queueGateway.add(createUploadCartQueueItem());
        }
    }
  };
}
