import React, {memo} from 'react';
import {ApolloProvider} from '@apollo/client';
import {IntlProvider} from 'react-intl';
import {Provider} from 'react-redux';
import {Persistor} from 'redux-persist/es/types';
import {PersistGate} from 'redux-persist/integration/react';
import {AppContainer} from '../../Container/Component/AppContainer';
import {ServiceContainer} from '../../Service/ServiceContainer/ServiceContainer';
import {Store} from '../../Store';

interface Props {
  persistor: Persistor;
  store: Store;
}

const {translator, graphQlClient} = ServiceContainer;

export const AppProvider: React.FC<Props> = memo<Props>(({store, persistor}) => (
  <ApolloProvider client={graphQlClient.getClient()}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <IntlProvider {...translator.getConfig()}>
          <AppContainer/>
        </IntlProvider>
      </PersistGate>
    </Provider>
  </ApolloProvider>
));
