import {gql} from 'apollo-boost';

export const productsQuery = gql`
    query ProductsQuery {
        products {
            id
            name
            categoryId
            stock
        }
    }
`;
