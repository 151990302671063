import {initialized} from '../App/action';
import {Middleware} from '../index';
import {PersistType} from './action';

export class PersistMiddleware {
  public middleware: Middleware = store => next => action => {
    next(action);

    switch (action.type) {
      case PersistType.Rehydrate:
        store.dispatch(initialized());
    }
  };
}
