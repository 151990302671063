import {useQuery} from '@apollo/client';
import React, {memo} from 'react';
import {RouteComponentProps} from 'react-router';
import {ErrorComponent} from '../../../Component/Error/ErrorComponent';
import {LoadingComponent} from '../../../Component/Loading/LoadingComponent';
import {AdminForm} from '../../Component/Form/AdminForm';
import {AdminCrudPage} from '../../Component/Page/AdminCrudPage';
import {getCategoryEditQuery} from '../../GraphQL/Query/getCategoryEditQuery';
import {getCategoryEdit} from '../../GraphQL/Query/types/getCategoryEdit';
import {FormValues} from '../../Model/Form/FormValues';

type Props = RouteComponentProps<{
  id: string;
}>;

export const CategoryAdminEditScreen: React.FC<Props> = memo(({match}) => {
  const {loading, error, data} = useQuery<getCategoryEdit>(getCategoryEditQuery, {
    fetchPolicy: 'network-only',
    variables: {id: parseInt(match.params.id, 10)},
  });

  let form;
  if (data) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const {__typename, organisations, ...initialValues} = data.category;
    (initialValues as unknown as FormValues).organisations = organisations.map(organisation => organisation.id);
    form = (
      <AdminForm
        initialValues={initialValues as unknown as FormValues}
        mutationName="editCategory"
        redirectUrl="/admin/category"
      />
    );
  }

  return (
    <AdminCrudPage entityType="category" action="Edit">
      {loading && <LoadingComponent/>}
      {(!loading && (error || !data)) && <ErrorComponent/>}
      {form}
    </AdminCrudPage>
  );
}, (prevProps, nextProps) => prevProps.match.params.id === nextProps.match.params.id);
