import {useDispatch} from 'react-redux';
import {Dispatch} from '../../Store';
import {hideToast, showToast} from '../Store/action';

const sleep = (ms: number): Promise<void> => new Promise(resolve => setTimeout(resolve, ms));

let lastToast = 0;

const handleToast = async (dispatch: Dispatch, message: string, duration: number) => {
  const id = ++lastToast;
  dispatch(showToast({id, message, duration}));

  await sleep(duration);
  dispatch(hideToast(id));
};

export const useToast = () => {
  const dispatch = useDispatch();

  return (message: string, duration = 3000) => handleToast(dispatch, message, duration);
};
