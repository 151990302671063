import {IonFab, IonFabButton, IonIcon} from '@ionic/react';
import {DocumentNode} from 'graphql';
import {add} from 'ionicons/icons';
import React, {memo, ReactNode} from 'react';
import {SortFunction} from '../../../Utils/SortFunction';
import {ColumnFormatting} from '../../Model/Form/ColumnFormatting';
import {AdminList} from '../List/AdminList';
import {AdminCrudPage} from './AdminCrudPage';

interface Props {
  columnFormatting?: ColumnFormatting;
  deleteMutation?: DocumentNode;
  disableAdd?: boolean;
  entityType: string;
  getQuery: DocumentNode;
  queryVariables?: Record<string, any>;
  sortFunction?: SortFunction<any>;
}

export const AdminListPage: React.FC<Props> = memo(({
  entityType, getQuery, queryVariables, deleteMutation, disableAdd, sortFunction, columnFormatting = {},
}) => {
  let addButton: ReactNode = null;
  if (!disableAdd) {
    addButton = (
      <IonFab vertical="bottom" horizontal="end" slot="fixed">
        <IonFabButton routerLink={`/admin/${entityType}/add`}>
          <IonIcon icon={add}/>
        </IonFabButton>
      </IonFab>
    );
  }

  return (
    <AdminCrudPage entityType={entityType} action="List">
      <AdminList
        entityType={entityType}
        getQuery={getQuery}
        queryVariables={queryVariables}
        deleteMutation={deleteMutation}
        sortFunction={sortFunction}
        columnFormatting={columnFormatting}
      />
      {addButton}
    </AdminCrudPage>
  );
});
