import {
  IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar,
} from '@ionic/react';
import React, {memo} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {ProductListComponent} from '../Component/ProductList/ProductListComponent';
import {CartFooterContainer} from '../Container/Component/CartFooterContainer';
import {CartIconContainer} from '../Container/Component/CartIconContainer';
import {useFavoriteProducts} from '../Hook/UseFavoriteProducts';

export const FavoriteProductsScreen: React.FC = memo(() => {
  const products = useFavoriteProducts();
  const {formatMessage} = useIntl();
  const imageAttribute = formatMessage({id: 'Label.FavoriteLikeThis'});

  const tutorial = (
    <>
      <h3><FormattedMessage id="Label.FavoriteLikeThis"/></h3>
      <img
        className="add-to-favorites"
        src="assets/images/AddToFavoritesTutorial.gif"
        alt={imageAttribute}
      />
    </>
  );

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton text={formatMessage({id: 'Label.Back'})} defaultHref="/categories"/>
          </IonButtons>
          <IonTitle><FormattedMessage id="Title.Favorites"/></IonTitle>
          <IonButtons slot="end">
            <CartIconContainer/>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {products.length < 1 ? tutorial : <ProductListComponent products={products}/>}
      </IonContent>
      <CartFooterContainer/>
    </IonPage>
  );
});
