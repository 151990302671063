import {Device} from '@capacitor/device';
import {Config} from '../../Model/Environment/Config';
import {EnvironmentEnum} from '../../Model/Environment/EnvironmentEnum';
import {PlatformEnum} from '../../Model/PlatformEnum';
import {environmentSelected} from '../../Store/Environment/action';
import {StoreGateway} from '../Store/StoreGateway';

interface ConfigMap {
  [EnvironmentEnum.Development]: Config;
  [EnvironmentEnum.Test]: Config;
  [EnvironmentEnum.Acceptance]: Config;
  [EnvironmentEnum.Production]: Config;
}

export class ConfigGateway {
  public constructor(
    private storeGateway: StoreGateway,
    private configDev: Config,
    private configTest: Config,
    private configAcc: Config,
    private configProd: Config,
  ) {
  }

  public async fetchEnvironment(): Promise<void> {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {platform} = await Device.getInfo();

    if (platform !== PlatformEnum.web) {
      return;
    }

    const result = await fetch('/assets/environment.json');
    if (!result.ok) {
      throw new Error('Could not fetch environment');
    }
    const json = await result.json();
    if (!json.environment) {
      throw new Error('Could not parse environment');
    }
    this.storeGateway.dispatch(environmentSelected(json.environment));
  }

  public getConfig() {
    return this.configMap()[this.storeGateway.getState().environment.environment];
  }

  private configMap(): ConfigMap {
    return {
      [EnvironmentEnum.Development]: this.configDev,
      [EnvironmentEnum.Test]: this.configTest,
      [EnvironmentEnum.Acceptance]: this.configAcc,
      [EnvironmentEnum.Production]: this.configProd,
    };
  }
}
