import React, {memo} from 'react';
import {AdminListPage} from '../../Component/Page/AdminListPage';
import {removeOrganisationMutation} from '../../GraphQL/Mutation/removeOrganisationMutation';
import {getOrganisationListQuery} from '../../GraphQL/Query/getOrganisationListQuery';

export const OrganisationAdminListScreen: React.FC = memo(() => (
  <AdminListPage
    entityType="organisation"
    getQuery={getOrganisationListQuery}
    deleteMutation={removeOrganisationMutation}
  />
));
