import {IonIcon, IonRippleEffect} from '@ionic/react';
import {add, remove, trash} from 'ionicons/icons';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {decreased, increased} from '../../Store/Cart/action';
import {ManualQuantityComponent} from '../ManualQuantity/ManualQuantityComponent';

interface Props {
  amountInCart: number;
  productId: number;
}

export const ProductQuantityChanger: React.FC<Props> = ({amountInCart, productId}) => {
  const dispatch = useDispatch();

  const decrease = () => dispatch(decreased(productId));
  const increase = () => dispatch(increased(productId));

  const [expandProductAmount, setExpandProductAmount] = useState<boolean>(false);
  const [expanderTimeout, setExpanderTimeout] = useState<any | null>(null);

  const resetExpandTimeout = () => {
    setExpandProductAmount(true);

    if (expanderTimeout) {
      clearTimeout(expanderTimeout);
    }

    setExpanderTimeout(setTimeout(() => {
      setExpandProductAmount(false);
    }, 2000));
  };

  const resetExpandStateAndIncrement = () => {
    if (amountInCart > 0 && !expandProductAmount) {
      setExpandProductAmount(true);
    } else {
      increase();
    }
    resetExpandTimeout();
  };

  const resetExpandStateAndDecrement = () => {
    if (amountInCart === 1) {
      setExpandProductAmount(false);
    } else {
      resetExpandTimeout();
    }
    decrease();
  };

  return (
    <div className="product__add">
      <div className={`product-amount-buttons${expandProductAmount ? ' product-amount-buttons--expanded' : ''}`}>
        <div className="product-amount-buttons__expander">
          <button
            type="button"
            onClick={resetExpandStateAndDecrement}
            className="product-amount-buttons__button ion-activatable"
          >
            <IonIcon icon={amountInCart === 1 ? trash : remove}/>
            <IonRippleEffect/>
          </button>
          <span className="product-amount-buttons__amount">
            <ManualQuantityComponent productId={productId} quantity={amountInCart}/>
          </span>
        </div>
        <button
          type="button"
          onClick={resetExpandStateAndIncrement}
          className={`product-amount-buttons__button ion-activatable${amountInCart > 0 ? ' product-amount-buttons__button--outline' : ''}`}
        >
          {!expandProductAmount && amountInCart > 0 ? amountInCart : <IonIcon icon={add}/>}
          <IonRippleEffect/>
        </button>
      </div>
    </div>
  );
};
