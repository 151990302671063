import {Toast} from '../Model/Toast';

export enum ToastType {
  ShowToast = 'showToast',
  HideToast = 'hideToast',
}

export interface ShowToastAction {
  toast: Toast;
  type: ToastType.ShowToast;
}

export interface HideToastAction {
  toastId: number;
  type: ToastType.HideToast;
}

export const showToast = (toast: Toast): ShowToastAction => ({
  type: ToastType.ShowToast,
  toast,
});

export const hideToast = (toastId: number): HideToastAction => ({
  type: ToastType.HideToast,
  toastId,
});

export type ToastActions =
    | ShowToastAction
    | HideToastAction;
