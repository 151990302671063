import {Config} from '../Model/Environment/Config';

export const configAcc: Config = {
  serverUrl: 'https://server.groentehal.nedbase.cloud',
  publicKey: '-----BEGIN CERTIFICATE-----\n'
        + 'MIIFpjCCA44CCQD/SZdMTp26iTANBgkqhkiG9w0BAQsFADCBlDELMAkGA1UEBhMC\n'
        + 'TkwxEDAOBgNVBAgMB1plZWxhbmQxEzARBgNVBAcMCk1pZGRlbGJ1cmcxHjAcBgNV\n'
        + 'BAoMFUdyb2VudGVoYWwgTWlkZGVsYnVyZzEbMBkGA1UEAwwSR3JvZW50ZWhhbCBC\n'
        + 'MkIgQXBwMSEwHwYJKoZIhvcNAQkBFhJpbmZvQGdyb2VudGVoYWwubmwwHhcNMjAw\n'
        + 'MjE0MTIyNTU4WhcNMjAwMzE1MTIyNTU4WjCBlDELMAkGA1UEBhMCTkwxEDAOBgNV\n'
        + 'BAgMB1plZWxhbmQxEzARBgNVBAcMCk1pZGRlbGJ1cmcxHjAcBgNVBAoMFUdyb2Vu\n'
        + 'dGVoYWwgTWlkZGVsYnVyZzEbMBkGA1UEAwwSR3JvZW50ZWhhbCBCMkIgQXBwMSEw\n'
        + 'HwYJKoZIhvcNAQkBFhJpbmZvQGdyb2VudGVoYWwubmwwggIiMA0GCSqGSIb3DQEB\n'
        + 'AQUAA4ICDwAwggIKAoICAQDlCVJKvZ8qhPesW7sRvUsX+DN2oBlL7e4Ve0+CSjQB\n'
        + '6ylUH7t8LhrNczHg/ijbXEHsk8trfh5j4HX/1T6jtJUxvGY3NLH1b3rfwahVX8jt\n'
        + 'E39Hao/I3PCfa/X2KBFWDl7/DSpJLo7rNO7zEmwNMmZhC/WTtwNKVynLUR58EAIx\n'
        + 'P1cNk9SOvrXR289a53f2LklySq/Y9Ngni/DEdeWtEN2miVTVTLkS22TAeBaVlFSh\n'
        + 'hBghRbVtD1m/NrSDz+nRfJhWQZBAwbDfJDIF63k4wqKGotCRaSpEpmwDuOTb85vu\n'
        + 'yWfTNm44HCox67/Cve6p7iHVUOyZyqCT7r3XJtWJJ8c+o0VPqEkHrQZHxAqiXaXQ\n'
        + 'Vl+K4mSp2j+1z9MMEkMmVlV1glhZgF5dLuY1wbED0VzKPYiJtW5vER1FI6PI9KyX\n'
        + 'ao0lcAEdksoi4IsbjnCteQmqtuP0qlg7fpCsHp0flz48VvB0kr8Tzcec4JidAicK\n'
        + 'mdiyy4cDu3Fuuw0ZdMizUARAoFgx/loWOZQIENlB6/o/vDS/7y8zdnfJaCT/jxsL\n'
        + 'B2lQws4JW9kHd8w+sV3eoDouK0lju+1+Z2tlE8HaF+C0kB13R/KrLcMtGSShbyUm\n'
        + 'JkCeajAvT1Dw8u8lpVrIb1LTutuQTmWfzFJ/3pqz2EnfzKzsNTMcaJc10q3tasST\n'
        + 'kQIDAQABMA0GCSqGSIb3DQEBCwUAA4ICAQBE81hvOq89Es7YztaiyycWinJktgkt\n'
        + 'MIq4KJ9/M4abhLAkFe1BzjNv+kii5p8fFStTts0iqIOJeHxPkZ4mn5HMUxvwJs9B\n'
        + 'XFTkCRQkx+fD9jGKQhsEiZ/6Ge21eM9Edvm4u3ALsZ4KhDMpw4wBrRLWEsRiJyYg\n'
        + '5Xph4zz8IvgAERF6LPf15ZfYw5XH24rBaCfXxeDa3GRPkuFH0ZA7E3ejZuX+VUoE\n'
        + 'yz0S836pO2rjG25VCcqyxKkBzj2LVx9ztU0RfYoM8+no6qmVHIuZ7bZRPdfnZsIG\n'
        + 'nFwnDIQ6dMzbhhm6EL4agTlWA7bxsBKQ3ph9DZQT1FA0/wW94tX2ACk1Gn7friaw\n'
        + 'ZnKWuuIS0K75LljhQUCBqUBN72EeiVaEyAPYXLlNFhZz+xkelRTr/+duPQ2Xm6R3\n'
        + 'yPhW2DHGqESbO/shpdV3hD+jk2CAWu/gDQmEfC6PrcaWCAdON0dWE3Gqddwg65Yx\n'
        + 'DOQbRuBp4FR4eV7Ly3bgCxws3IqsSmCi5F/H3BN3DZEZMqXCDMnAD1+RfcgI+x9X\n'
        + '/1LhEWHm0zVYPVx3KibAdNSaacsI7lhGdmJBMt12Qypi8WYNPNCa1PllSDbvWbzK\n'
        + 'RcJG0EeNtDGbhvYe/jyamGujSUU8qKiMpXJgCt/bxYJWfYNFoo2b/6zqh0ucljoS\n'
        + 'WHV95NNA3oBFpQ==\n'
        + '-----END CERTIFICATE-----\n',
};
