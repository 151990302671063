import {IonInput, IonLabel} from '@ionic/react';
import React, {memo, useCallback} from 'react';
import {FormattedMessage} from 'react-intl';
import {FormFieldProps} from '../../../Model/Form/FormFieldProps';
import {RequiredComponent} from '../Layout/Required';

interface Props extends FormFieldProps {
  value: string | undefined | null;
}

const defaultAttributes = {
  pattern: 'text',
  type: 'text',
};

export const TextField: React.FC<Props> = memo(({name, value, onChange, required, attributes = defaultAttributes}) => {
  const onIonChange = useCallback(e => onChange((e.target).value), [onChange]);
  return (
    <>
      <IonLabel position="floating">
        <FormattedMessage id={`Admin.Form.Field.${name}.Label`}/>
        <RequiredComponent required={required}/>
      </IonLabel>
      <IonInput
        name={name}
        value={value}
        required={required}
        onIonChange={onIonChange}
        {...attributes}
      />
    </>
  );
});
