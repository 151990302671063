import {IonIcon, IonItemOption} from '@ionic/react';
import {heart, heartOutline} from 'ionicons/icons';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../Store';
import {favoriteProductAdded, favoriteProductRemoved} from '../../Store/Favorite/action';

interface Props {
  closeParent: () => void;
  productId: number;
}

export const FavoriteIconComponent: React.FC<Props> = ({productId, closeParent}) => {
  const isFavorite = useSelector<RootState, boolean>(state => state.favorite.includes(productId));
  const dispatch = useDispatch();

  const toggle = () => {
    if (isFavorite) {
      dispatch(favoriteProductRemoved(productId));
    } else {
      dispatch(favoriteProductAdded(productId));
    }
    closeParent();
  };

  return (
    <IonItemOption slot="icon-only" color="light" onClick={toggle}>
      <IonIcon slot="icon-only" icon={isFavorite ? heart : heartOutline} color="danger"/>
    </IonItemOption>
  );
};
