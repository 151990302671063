import {IonItem, IonLabel, IonListHeader} from '@ionic/react';
import React, {memo} from 'react';
import {GetOrderDetailQuery_order_orderLines} from '../../GraphQL/Query/types/GetOrderDetailQuery';

interface Props {
  category: string;
  orderLines: GetOrderDetailQuery_order_orderLines[];
}

export const OrderLinesCategoryDetailComponent: React.FC<Props> = memo(({category, orderLines}) => {
  const products = orderLines.filter(({categoryName}) => categoryName === category).map(({productName, amount}) => (
    <IonItem key={productName}>
      <IonLabel>{productName}</IonLabel>
      {amount}
    </IonItem>
  ));
  return (
    <>
      <IonListHeader>
        <IonLabel>{category}</IonLabel>
      </IonListHeader>
      {products}
    </>
  );
});
