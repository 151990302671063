import {IonList} from '@ionic/react';
import React, {memo} from 'react';
import {FieldDefinition} from '../../../Model/Form/FieldDefinition';
import {FormErrorList} from '../../../Model/Form/FormErrorList';
import {FormValues} from '../../../Model/Form/FormValues';
import {OnChange} from '../../../Model/Form/OnChange';
import {DynamicFormField} from './DynamicFormField';

interface Props {
  errors: FormErrorList;
  fields: FieldDefinition[];
  formValues: FormValues;
  onChange: (fieldName: string) => OnChange;
}

export const DynamicFieldList: React.FC<Props> = memo(({
  fields, onChange, formValues, errors,
}) => {
  const renderedFields = fields.map(field => (
    <DynamicFormField
      key={field.name}
      field={field}
      onChange={onChange(field.name)}
      value={formValues[field.name]}
      errors={errors[field.name] !== undefined ? errors[field.name] : []}
    />
  ));

  return <IonList>{renderedFields}</IonList>;
});
