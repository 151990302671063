export enum PersistType {
  Rehydrate = 'persist/REHYDRATE',
}

export interface RehydrateAction {
  type: PersistType.Rehydrate;
}

export type PersistActions =
    | RehydrateAction;
