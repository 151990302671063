import {IonButton} from '@ionic/react';
import React, {memo, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {useDispatch} from 'react-redux';
import {useIsPlatform} from '../../../Hook/UseIsPlatform';
import {PlatformEnum} from '../../../Model/PlatformEnum';
import {loggedOut} from '../../../Store/Authentication/action';
import {AdminListComponent} from '../List/AdminListComponent';
import {AdminPage} from './AdminPage';

export const AdminHomePage: React.FC = memo(() => {
  const [isWeb, setIsWeb] = useState(false);
  useIsPlatform(PlatformEnum.web).then(platformIsWeb => {
    setIsWeb(platformIsWeb);
  });

  const dispatch = useDispatch();
  const logout = () => dispatch(loggedOut());

  const unsupportedMessage = (
    <>
      <FormattedMessage id="Label.PlatformNotSupported"/>
      <IonButton onClick={logout} className="ion-margin-vertical">
        <FormattedMessage id="Label.Logout"/>
      </IonButton>
    </>
  );

  return (
    <AdminPage title="Title.Admin">
      {isWeb ? <AdminListComponent/> : unsupportedMessage}
    </AdminPage>
  );
});
