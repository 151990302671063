import {IonFooter, IonIcon, IonToolbar} from '@ionic/react';
import {arrowForward} from 'ionicons/icons';
import React, {memo} from 'react';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import {CartFooterContainerProps} from '../../Container/Component/CartFooterContainer';

export const CartFooterComponent: React.FC<CartFooterContainerProps> = memo(({totalAmount}) => (
  <IonFooter>
    <IonToolbar color="secondary" className="ion-no-padding">
      <Link
        to="/cart"
        className="cart-button link-as-block"
      >
        <div className="cart-button__inner">
          <div className="cart-button__amount">
            {totalAmount}
          </div>
          <div className="cart-button__text">
            <span><FormattedMessage id="Label.Products"/></span>
          </div>
          <IonIcon icon={arrowForward} className="cart-button__icon"/>
        </div>
      </Link>
    </IonToolbar>
  </IonFooter>
));
