import {gql} from 'apollo-boost';

export const cartQuery = gql`
    query CartQuery {
        cart {
            amount
            productId
        }
    }
`;
