import {
  IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar,
} from '@ionic/react';
import React, {memo} from 'react';
import {useIntl} from 'react-intl';
import {shallowEqual, useSelector} from 'react-redux';
import {RouteComponentProps} from 'react-router';
import {ErrorComponent} from '../Component/Error/ErrorComponent';
import {ProductListComponent} from '../Component/ProductList/ProductListComponent';
import {CartFooterContainer} from '../Container/Component/CartFooterContainer';
import {CartIconContainer} from '../Container/Component/CartIconContainer';
import {Category} from '../Model/Category';
import {Product} from '../Model/Product';
import {RootState} from '../Store';

export const CategoryDetailScreen: React.FC<RouteComponentProps<{ id: string }>> = memo(({match}) => {
  const routeCategoryId = Number(match.params.id);
  const category = useSelector<RootState, Category | undefined>(state => state.category.find(({id}) => id === routeCategoryId));
  const products = useSelector<RootState, Product[]>(state => state.product.filter(({categoryId}) => categoryId === routeCategoryId), shallowEqual);
  const {formatMessage} = useIntl();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton text={formatMessage({id: 'Label.Back'})} defaultHref="/categories"/>
          </IonButtons>
          <IonTitle>{category ? category.name : <ErrorComponent/>}</IonTitle>
          <IonButtons slot="end">
            <CartIconContainer/>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollX={false} scrollY={false}>
        <ProductListComponent products={products}/>
      </IonContent>
      <CartFooterContainer/>
    </IonPage>
  );
}, (prevProps, nextProps) => prevProps.match.params.id === nextProps.match.params.id);
