import {useQuery} from '@apollo/client';
import React, {memo} from 'react';
import {ErrorComponent} from '../../../../../Component/Error/ErrorComponent';
import {LoadingComponent} from '../../../../../Component/Loading/LoadingComponent';
import {getCategorySelectQuery} from '../../../../GraphQL/Query/getCategorySelectQuery';
import {getCategorySelect} from '../../../../GraphQL/Query/types/getCategorySelect';
import {FormFieldProps} from '../../../../Model/Form/FormFieldProps';
import {SelectField} from '../SelectField';

interface Props extends FormFieldProps {
  multiple?: boolean;
  value: string | string[];
}

export const CategoryField: React.FC<Props> = memo(props => {
  const {loading, error, data} = useQuery<getCategorySelect>(getCategorySelectQuery);

  if (loading) {
    return <LoadingComponent/>;
  }
  if (error || !data) {
    return <ErrorComponent/>;
  }
  const options = data.categories.map(category => ({key: category.id, label: category.name}));
  return <SelectField options={options} {...props}/>;
});
