import React, {memo, useCallback, useState} from 'react';
import {useIntl} from 'react-intl';
import {Redirect} from 'react-router';
import {useToast} from '../../../Toaster/Hook/UseToast';
import {FieldDefinition} from '../../Model/Form/FieldDefinition';
import {FormValue} from '../../Model/Form/FormValue';
import {FormValues} from '../../Model/Form/FormValues';
import {Mutation} from '../../Model/Form/Mutation';
import {getDefaultValues} from '../../Service/GraphQL/DefaultValueExtractor';
import {createMutation} from '../../Service/GraphQL/MutationCreator';
import {GraphForm} from './GraphForm';

interface Props {
  initialValues?: Record<string, FormValue>;
  mutation: Mutation;
  redirectUrl: string;
}

const getInitialValues = (fields: FieldDefinition[], initialValues?: Record<string, FormValue>): FormValues => {
  if (initialValues) {
    return initialValues;
  }
  return getDefaultValues(fields);
};

export const MutationForm: React.FC<Props> = memo(({mutation, redirectUrl, initialValues}) => {
  const showToast = useToast();
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const {formatMessage} = useIntl();
  const onComplete = useCallback(() => {
    showToast(formatMessage({id: `Admin.Toast.Persisted.${mutation.name}`}));
    setShouldRedirect(true);
  }, [formatMessage, showToast, setShouldRedirect, mutation.name]);
  const fields = mutation.args[0].type.ofType.inputFields;
  if (shouldRedirect) {
    return <Redirect to={redirectUrl}/>;
  }
  return (
    <GraphForm
      fields={fields}
      initialValues={getInitialValues(fields, initialValues)}
      mutation={createMutation(mutation)}
      onComplete={onComplete}
    />
  );
});
