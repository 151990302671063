import {IonItem} from '@ionic/react';
import React, {memo} from 'react';
import {FormError} from '../../../Model/Form/FormError';
import {ErrorList} from './ErrorList';

interface Props {
  errors: FormError[];
  name: string;
  required: boolean;
}

export const Field: React.FC<Props> = memo(({
  children, name, errors,
}) => (
  <IonItem>
    {children}
    <ErrorList fieldName={name} errors={errors}/>
  </IonItem>
));
