import {gql} from 'apollo-boost';

export const getCategoryEditQuery = gql`
    query getCategoryEdit($id: Int!) {
        category(id: $id){
            id
            name
            image
            priority
            organisations{
                id
            }
        }
    }
`;
