import {EnvironmentEnum} from '../../Model/Environment/EnvironmentEnum';
import {Action} from '../action';
import {EnvironmentType} from './action';
import {EnvironmentState} from './state';

const initialState: EnvironmentState = {
  environment: EnvironmentEnum.Production,
};

export const environmentReducer = (state = initialState, action: Action): EnvironmentState => {
  switch (action.type) {
    case EnvironmentType.EnvironmentSelected:
      return {...state, environment: action.environment};
    default:
      return state;
  }
};
