export class Filter {
  private static removeSpaces(value: string): string {
    return value.replace(/ /g, '').toLowerCase();
  }

  public filter<T>(dataSet: T[], value: null | string | T, recursive = false, ignoreProperties: string[] = []): T[] {
    if (value === null) {
      return dataSet.slice();
    }

    if (typeof value === 'string') {
      return this.filterDataSetByProperties(dataSet, value, recursive, ignoreProperties);
    }

    return dataSet.filter(record => record === value);
  }

  private filterDataSetByProperties<T>(dataSet: T[], value: string, recursive: boolean, ignoreProperties: string[]): T[] {
    const searchString = Filter.removeSpaces(value);

    return dataSet.filter(record => {
      const properties = this.normalizeData(record, recursive, ignoreProperties);
      return properties.join('').toLowerCase().includes(searchString);
    });
  }

  private normalizeData<T>(record: T, recursive = true, ignoreProperties: string[]): string[] {
    let properties: any[] = [];

    for (const property of Object.keys(record)) {
      if (!ignoreProperties.includes(property)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const value = record[property];
        if (typeof value === 'string') {
          properties.push(Filter.removeSpaces(value));
        }
        if (recursive && typeof value === 'object') {
          properties = properties.concat(this.normalizeData(value, true, ignoreProperties));
        }
      }
    }

    // @todo refactor code to resolve technical depth (eslint)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return properties;
  }
}
