import {addOrderMutation} from '../../../GraphQL/Mutation/addOrderMutation';
import {AddOrder, AddOrderVariables} from '../../../GraphQL/Mutation/types/AddOrder';
import {CreateOrder} from '../../../Model/CreateOrder';
import {QueueCommand} from '../../../Model/Queue/QueueCommand';
import {QueueStatus} from '../../../Model/Queue/QueueStatus';
import {orderUpdated} from '../../../Store/Order/action';
import {GraphQlClient} from '../../GraphQl/GraphQlClient';
import {StoreGateway} from '../../Store/StoreGateway';

export class UploadOrderCommand implements QueueCommand {
  public constructor(private order: CreateOrder, private graphQlClient: GraphQlClient, private storeGateway: StoreGateway) {
  }

  public async execute(): Promise<QueueStatus> {
    try {
      const result = await this.graphQlClient.getClient().mutate<AddOrder, AddOrderVariables>({
        mutation: addOrderMutation,
        variables: {...this.order, deliveryDate: this.order.deliveryDate},
      });
      if (!result) {
        return QueueStatus.Failed;
      }
      if (!result.data) {
        return QueueStatus.Failed;
      }

      const order = this.storeGateway.getState().orders.find(({uuid}) => uuid === this.order.uuid)!;
      const updatedOrder = {
        ...order,
        id: result.data.addOrder.id,
        orderDate: result.data.addOrder.orderDate,
      };
      this.storeGateway.dispatch(orderUpdated(updatedOrder));
      return QueueStatus.Done;
    } catch (e) {
      console.error(e);
    }
    return QueueStatus.Retry;
  }
}
