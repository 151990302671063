import {QueueItem} from '../../Model/Queue/QueueItem';
import {QueueStatus} from '../../Model/Queue/QueueStatus';

export enum QueueType {
  QueueRunnerStarted = 'QueueRunnerStarted',
  QueueItemAdded = 'queueItemAdded',
  QueueItemUpdated = 'queueItemUpdated',
  RefreshData = 'refreshData',
}

export interface QueueItemAddedAction {
  item: QueueItem;
  type: QueueType.QueueItemAdded;
}

export interface QueueItemUpdatedAction {
  id: string;
  status: QueueStatus;
  type: QueueType.QueueItemUpdated;
}

export interface RefreshDataAction {
  type: QueueType.RefreshData;
}

export interface QueueRunnerStartedAction {
  type: QueueType.QueueRunnerStarted;
}

export const queueItemAdded = (item: QueueItem): QueueItemAddedAction => ({
  type: QueueType.QueueItemAdded,
  item,
});

export const queueItemUpdated = (id: string, status: QueueStatus): QueueItemUpdatedAction => ({
  type: QueueType.QueueItemUpdated,
  id,
  status,
});

export const refreshData = (): RefreshDataAction => ({
  type: QueueType.RefreshData,
});

export const queueRunnerStarted = (): QueueRunnerStartedAction => ({
  type: QueueType.QueueRunnerStarted,
});

export type QueueActions =
    | QueueItemAddedAction
    | QueueItemUpdatedAction
    | RefreshDataAction
    | QueueRunnerStartedAction;
