import {IonCard, IonRippleEffect} from '@ionic/react';
import React, {memo} from 'react';
import {Link} from 'react-router-dom';
import {Category} from '../../Model/Category';

interface Props {
  category: Category;
}

export const CategoryListItemComponent: React.FC<Props> = memo(({category}) => (
  <IonCard class="category-card">
    <Link to={`/category/${category.id}`} className="link-as-block ion-activatable">
      <img src={category.image} alt={category.name}/>
      <h2>{category.name}</h2>
      <IonRippleEffect/>
    </Link>
  </IonCard>
));
