import {
  IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar,
} from '@ionic/react';
import React, {memo} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {RouteComponentProps} from 'react-router';
import {ErrorComponent} from '../Component/Error/ErrorComponent';
import {OrderDetailComponent} from '../Component/Order/OrderDetailComponent';
import {Order} from '../Model/Order';
import {RootState} from '../Store';
import {formatOrderNumber} from '../Utils/OrderNumberFormatter';

export const OrderDetailScreen: React.FC<RouteComponentProps<{ uuid: string }>> = memo(({match}) => {
  const order = useSelector<RootState, Order | undefined>(state => state.orders.find(({uuid}) => uuid === match.params.uuid));
  const {formatMessage} = useIntl();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton text={formatMessage({id: 'Label.Back'})} defaultHref="/"/>
          </IonButtons>
          <IonTitle>
            {order!.id && <FormattedMessage id="Title.Order" values={{id: formatOrderNumber(order!.id)}}/>}
            {!order!.id && <FormattedMessage id="Title.OrderOffline"/>}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {order && <OrderDetailComponent order={order}/>}
        {!order && <ErrorComponent/>}
      </IonContent>
    </IonPage>
  );
}, (prevProps, nextProps) => prevProps.match.params.uuid === nextProps.match.params.uuid);
