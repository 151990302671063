import React, {memo, useCallback} from 'react';
import {FormFieldProps} from '../../../Model/Form/FormFieldProps';
import {TextField} from './TextField';

interface Props extends FormFieldProps {
  value: string | undefined | null;
}

export const DecimalField: React.FC<Props> = memo(({
  onChange, attributes, value, ...passthroughProps
}) => {
  attributes = {...attributes, inputmode: 'decimal', type: 'number'};
  const onNumber = useCallback(newNumber => {
    if (newNumber && typeof newNumber === 'string') {
      newNumber = parseFloat(newNumber);
    }
    onChange(newNumber);
  }, [onChange]);
  return (
    <TextField {...passthroughProps} attributes={attributes} value={value} onChange={onNumber}/>
  );
});
