import {Token} from '../../Model/Authentication/Token';

export enum AuthenticationType {
  UsernameEntered = 'UsernameEntered',
  TokenReceived = 'TokenReceived',
  Authenticated = 'Authenticated',
  LoggedOut = 'LoggedOut',
}

export interface UsernameEnteredAction {
  type: AuthenticationType.UsernameEntered;
  username: string;
}

export interface TokenReceivedAction {
  token: string;
  type: AuthenticationType.TokenReceived;
}

export interface AuthenticatedAction {
  decodedToken: Token;
  isNewUser: boolean;
  type: AuthenticationType.Authenticated;
}

export interface LoggedOutAction {
  type: AuthenticationType.LoggedOut;
}

export const usernameEntered = (username: string): UsernameEnteredAction => ({
  type: AuthenticationType.UsernameEntered,
  username,
});

export const tokenReceived = (token: string): TokenReceivedAction => ({
  type: AuthenticationType.TokenReceived,
  token,
});

export const authenticated = (decodedToken: Token, isNewUser: boolean): AuthenticatedAction => ({
  type: AuthenticationType.Authenticated,
  decodedToken,
  isNewUser,
});

export const loggedOut = (): LoggedOutAction => ({
  type: AuthenticationType.LoggedOut,
});

export type AuthenticationActions =
    | UsernameEnteredAction
    | TokenReceivedAction
    | AuthenticatedAction
    | LoggedOutAction;
