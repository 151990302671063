import {QueueStatus} from '../../Model/Queue/QueueStatus';
import {AuthenticationType} from '../Authentication/action';
import {Action} from '../action';
import {QueueType} from './action';
import {QueueState} from './state';

export const initialState: QueueState = [];

export const queueReducer = (state = initialState, action: Action): QueueState => {
  switch (action.type) {
    case QueueType.QueueRunnerStarted:
      return state.map(queueItem => {
        if (queueItem.status === QueueStatus.InProgress) {
          return {...queueItem, status: QueueStatus.Queued};
        }
        return queueItem;
      });
    case QueueType.QueueItemAdded:
      return [...state, action.item];
    case QueueType.QueueItemUpdated:
      if (action.status === QueueStatus.Done) {
        return state.filter(({id}) => id !== action.id);
      }
      return state.map(queueItem => {
        if (queueItem.id === action.id) {
          return {...queueItem, status: action.status};
        }
        return queueItem;
      });
    case AuthenticationType.Authenticated:
      return action.isNewUser ? initialState : state;
    default:
      return state;
  }
};
