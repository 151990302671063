import localForage from 'localforage';
import {
  applyMiddleware, combineReducers, createStore, Dispatch as ReduxDispatch, MiddlewareAPI as ReduxMiddlewareAPI, Store as ReduxStore,
} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import {persistReducer, persistStore} from 'redux-persist';
import {PersistConfig, Persistor} from 'redux-persist/es/types';
import {toastReducer} from '../Toaster/Store/reducer';
import {Action} from './action';

import {authenticationReducer} from './Authentication/reducer';
import {cartReducer} from './Cart/reducer';
import {categoryReducer} from './Category/reducer';
import {environmentReducer} from './Environment/reducer';
import {favoriteReducer} from './Favorite/reducer';
import {orderReducer} from './Order/reducer';
import {productReducer} from './Product/reducer';
import {queueReducer} from './Queue/reducer';
import {deliveryDateTransformer} from './Transformers/DeliveryDateTransformer';

export type Store = ReduxStore<RootState, Action>;
export type Dispatch = ReduxDispatch<Action>;
export type MiddlewareApi = ReduxMiddlewareAPI<Dispatch, RootState>;
// @todo refactor code to resolve technical depth (eslint)
// eslint-disable-next-line @typescript-eslint/ban-types,@typescript-eslint/no-unused-vars
export type Middleware<DispatchExt = {}, S = RootState, D = Dispatch> =
    (api: MiddlewareApi) => (next: Dispatch) => (action: Action) => Promise<void> | void;

export const rootReducer = combineReducers({
  authentication: authenticationReducer,
  environment: environmentReducer,
  favorite: favoriteReducer,
  cart: cartReducer,
  toasts: toastReducer,
  queue: queueReducer,
  orders: orderReducer,
  category: categoryReducer,
  product: productReducer,
});

localForage.config({
  driver: [localForage.INDEXEDDB, localForage.WEBSQL, localForage.LOCALSTORAGE],
  name: 'groentehal',
  storeName: 'state',
});

const persistConfig: PersistConfig<RootState> = {
  key: 'root',
  storage: localForage,
  blacklist: ['toasts'],
  transforms: [deliveryDateTransformer],
};

const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer);

export type RootState = ReturnType<typeof rootReducer>;

export class StoreConfigurator {
  public constructor(private middleware: Middleware[]) {
  }

  public initializeStore(previousState?: any): { persistor: Persistor; store: Store } {
    const middlewareEnhancer = applyMiddleware(...this.middleware);
    const composedEnhancers = composeWithDevTools(middlewareEnhancer);

    const store = createStore(persistedReducer, previousState, composedEnhancers);
    const persistor = persistStore(store);
    return {store, persistor};
  }
}
