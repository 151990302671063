import {cartQuery} from '../../../GraphQL/Query/cartQuery';
import {CartQuery} from '../../../GraphQL/Query/types/CartQuery';
import {QueueCommand} from '../../../Model/Queue/QueueCommand';
import {QueueStatus} from '../../../Model/Queue/QueueStatus';
import {cartLoaded} from '../../../Store/Cart/action';
import {GraphQlClient} from '../../GraphQl/GraphQlClient';
import {StoreGateway} from '../../Store/StoreGateway';

export class DownloadCartCommand implements QueueCommand {
  public constructor(private graphQlClient: GraphQlClient, private storeGateway: StoreGateway) {
  }

  public async execute(): Promise<QueueStatus> {
    try {
      const result = await this.graphQlClient.getClient().query<CartQuery>({query: cartQuery, fetchPolicy: 'network-only'});
      if (!result) {
        return QueueStatus.Failed;
      }
      const cartItems = result.data.cart.map(cart => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const {__typename, ...strippedCart} = cart;
        return strippedCart;
      });
      this.storeGateway.dispatch(cartLoaded(cartItems));
      return QueueStatus.Done;
    } catch (e) {
      console.error(e);
    }
    return QueueStatus.Retry;
  }
}
