/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum Role {
    Admin = 'Admin',
    Anonymous = 'Anonymous',
    User = 'User',
}

//==============================================================
// END Enums and Input Objects
//==============================================================
