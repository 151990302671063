import {
  IonDatetime, IonItem, IonLabel, IonList, IonListHeader, IonTextarea,
} from '@ionic/react';
import {DateTime} from 'luxon';
import React, {memo, useCallback} from 'react';
import {FormattedMessage} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {CartItem} from '../../Model/CartItem';
import {getFirstAvailableDeliveryDate, getMaximumDeliveryDate} from '../../Service/DeliveryDate/DeliveryDate';
import {ServiceContainer} from '../../Service/ServiceContainer/ServiceContainer';
import {RootState} from '../../Store';
import {deliveryDateSet, remarkSet} from '../../Store/Cart/action';
import {CartItemComponent} from './CartItemComponent';

export const CartOverviewComponent: React.FC = memo(() => {
  const dispatch = useDispatch();
  const cart = useSelector<RootState, CartItem[]>(state => state.cart.cartItems);
  const remark = useSelector<RootState, string>(state => state.cart.remark);
  const deliveryDate = useSelector<RootState, DateTime>(state => state.cart.deliveryDate);

  const changeDeliveryDate = useCallback((ev: any) => dispatch(deliveryDateSet(DateTime.fromISO(ev.target.value))), [dispatch]);
  const changeRemark = useCallback((ev: any) => dispatch(remarkSet(ev.target.value)), [dispatch]);

  const {translator} = ServiceContainer;

  return (
    <>
      <IonList>
        <IonListHeader className="list-header--padding-small">
          <FormattedMessage id="Label.YourProducts"/>
        </IonListHeader>
        {cart.length === 0 && <IonItem className="list-item--padding-small"><FormattedMessage id="Label.EmptyCart"/></IonItem>}
        {cart.map((cartItem: CartItem) => <CartItemComponent cartItem={cartItem} key={cartItem.productId}/>)}
      </IonList>
      <IonList>
        <IonListHeader className="list-header--padding-small">
          <FormattedMessage id="Label.DeliveryDate"/>
        </IonListHeader>
        <IonItem className="list-item--padding-small">
          <IonLabel position="floating"><FormattedMessage id="Label.ChooseDate"/></IonLabel>
          <IonDatetime
            displayFormat="DD-MM-YYYY"
            min={getFirstAvailableDeliveryDate().toString()}
            max={getMaximumDeliveryDate().toString()}
            value={deliveryDate.toString()}
            onIonChange={changeDeliveryDate}
            doneText={translator.translate('Label.Datepicker.ok')}
            cancelText={translator.translate('Label.Datepicker.cancel')}
            mode="ios"
          />
        </IonItem>
      </IonList>
      <IonList>
        <IonListHeader className="list-header--padding-small">
          <FormattedMessage id="Label.OrderRemark"/>
        </IonListHeader>
        <IonItem className="list-item--padding-small">
          <IonLabel position="floating"><FormattedMessage id="Label.YourRemark"/></IonLabel>
          <IonTextarea onIonChange={changeRemark} value={remark} debounce={500}/>
        </IonItem>
      </IonList>
    </>
  );
});
