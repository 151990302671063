import {IonButton, IonIcon, useIonViewDidEnter} from '@ionic/react';
import {useQuery} from '@apollo/client';
import flatten from 'flat';
import {DocumentNode} from 'graphql';
import {create} from 'ionicons/icons';
import React, {memo} from 'react';
import {IDataTableColumn} from 'react-data-table-component';
import {FormattedMessage, useIntl} from 'react-intl';
import {ErrorComponent} from '../../../Component/Error/ErrorComponent';
import {LoadingComponent} from '../../../Component/Loading/LoadingComponent';
import {useRunMutation} from '../../../Hook/UseRunMutation';
import {useToast} from '../../../Toaster/Hook/UseToast';
import {SortFunction} from '../../../Utils/SortFunction';
import {ColumnFormatting} from '../../Model/Form/ColumnFormatting';
import {filterHiddenFields} from '../../Service/Form/HiddenFieldFilter';
import {DeleteButton} from '../Layout/DeleteButton';
import {EntityTable} from '../Table/EntityTable';

interface Props {
  columnFormatting?: ColumnFormatting;
  deleteMutation?: DocumentNode;
  entityType: string;
  getQuery: DocumentNode;
  queryVariables?: Record<string, any>;
  sortFunction?: SortFunction<any>;
}

type GraphResponse = Record<string, Array<Record<string, any>>>;

const extractHeaders = (result: GraphResponse): string[] => {
  const dataKey = Object.keys(result)[0];
  const entity = result[dataKey][0];
  return entity ? Object.keys(flatten(entity)) : [];
};

export const AdminList: React.FC<Props> = memo(({
  entityType,
  getQuery,
  queryVariables,
  deleteMutation,
  sortFunction,
  columnFormatting = {},
}) => {
  const {
    loading, error, data, refetch,
  } = useQuery<GraphResponse>(getQuery, {variables: queryVariables});
  const runMutation = useRunMutation();
  const {formatMessage} = useIntl();
  const showToast = useToast();

  useIonViewDidEnter(() => {
    refetch();
  });

  if (loading) {
    return <LoadingComponent/>;
  }
  if (error || !data) {
    return <ErrorComponent/>;
  }

  const onDelete = (id: number) => async () => {
    if (deleteMutation) {
      try {
        await runMutation(deleteMutation, {id});
        await refetch();
      } catch (e) {
        const mutationName = `remove${entityType.charAt(0).toUpperCase()}${entityType.slice(1)}`;
        await showToast(formatMessage({id: `Admin.Toast.Error.${mutationName}`}));
      }
    }
  };
  const index = Object.keys(data).find(() => true) as string;
  let dataRows = data ? data[index] : [];
  if (sortFunction !== undefined) {
    dataRows = [...dataRows].sort(sortFunction);
  }
  const actionColumn = (row: any) => {
    const rowId = row.id as number;
    return (
      <>
        <IonButton routerLink={`/admin/${entityType}/${rowId}/edit`}>
          <IonIcon icon={create}/>
        </IonButton>
        <DeleteButton canDelete={!!deleteMutation} entityType={entityType} onDelete={onDelete(rowId)}/>
      </>
    );
  };

  const headers = extractHeaders(data);
  if (headers.length === 0) {
    return <FormattedMessage id="Admin.Column.empty"/>;
  }
  const columns: Array<IDataTableColumn> = data ? filterHiddenFields(headers).map(column => ({
    name: formatMessage({id: `Admin.Column.${column}`}),
    selector: column,
    format: columnFormatting[column],
    sortable: true,
  })) : [];
  columns.push({
    name: formatMessage({id: 'Admin.Column.Actions'}),
    cell: actionColumn,
  });
  return <EntityTable columns={columns} data={dataRows}/>;
});
