import {FormErrorList} from '../../Model/Form/FormErrorList';
import {GraphQueryError} from '../../Model/GraphQL/GraphQueryError';
import {GraphValidationError} from '../../Model/GraphQL/GraphValidationError';

const extractContext = (validationError: { constraints: Record<string, string>; contexts: Record<string, Record<string, any>>; property: string }, errorName: string) => {
  if (validationError.contexts === undefined) {
    return undefined;
  }
  if (validationError.contexts[errorName] === undefined) {
    return undefined;
  }
  return validationError.contexts[errorName];
};

const errorReducer = (accumulator: FormErrorList, error: GraphValidationError): FormErrorList => {
  if (!error.extensions.exception.validationErrors) {
    return accumulator;
  }
  return error.extensions.exception.validationErrors.reduce((accumulator2: FormErrorList, validationError) => {
    if (accumulator2[validationError.property] === undefined) {
      accumulator2[validationError.property] = [];
    }

    const errors = Object.keys(validationError.constraints).map(errorName => ({
      context: extractContext(validationError, errorName),
      errorName,
    }));

    accumulator2[validationError.property].push(...errors);
    return accumulator2;
  }, accumulator);
};

export const extractErrors = (graphError: GraphQueryError | undefined): FormErrorList => {
  let errors: FormErrorList = {};
  if (graphError === undefined) {
    return errors;
  }
  if (graphError.graphQLErrors) {
    errors = graphError.graphQLErrors.reduce(errorReducer, errors);
  }

  if (graphError.networkError) {
    errors = graphError.networkError.result.errors.reduce(errorReducer, errors);
  }

  return errors;
};
