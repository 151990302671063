import flatten from 'flat';
import {createIntl, IntlFormatters} from 'react-intl';

export class Translator {
  private config: { locale: any; messages: any };

  private formatter: IntlFormatters;

  public constructor(locale: string) {
    const translations = Translator.getTranslations(locale);
    const messages = flatten(translations);
    this.config = {locale, messages};
    this.formatter = createIntl(this.config);
  }

  private static getTranslations(locale: string) {
    // eslint-disable-next-line global-require,import/no-dynamic-require,@typescript-eslint/no-unsafe-return
    return require(`../../Config/Translation/${locale}.json`);
  }

  public getConfig() {
    return this.config;
  }

  public hasTranslation(key: string): boolean {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.config.messages[key];
  }

  public translate(key: string): string {
    if (this.hasTranslation(key)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return this.config.messages[key];
    }

    return key;
  }
}
