import {TypeKind} from 'graphql';
import {FieldDefinition} from '../../Model/Form/FieldDefinition';
import {FormValue} from '../../Model/Form/FormValue';
import {FormValues} from '../../Model/Form/FormValues';
import {TypeName} from '../../Model/GraphQL/TypeName';

const getDefaultValueForField = (field: FieldDefinition): FormValue => {
  if (field.type.kind !== TypeKind.NON_NULL) {
    return undefined;
  }

  switch (field.type.ofType.kind) {
    case TypeKind.LIST:
      return [];
    case TypeKind.SCALAR:
      switch (field.type.ofType.name) {
        case TypeName.String:
          return '';
        case TypeName.Int:
          return null;
      }
  }

  return null;
};

export const getDefaultValues = (fields: FieldDefinition[]): FormValues => fields.reduce(
  (accumulator: FormValues, field) => ({...accumulator, [field.name]: getDefaultValueForField(field)}),
  {},
);
