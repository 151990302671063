import {connect} from 'react-redux';
import {CartIconComponent} from '../../Component/Cart/CartIconComponent';
import {RootState} from '../../Store';

interface OwnProps {
}

type StateProps = OwnProps & {
  amount: number;
};

interface DispatchProps {
}

export type CartIconContainerProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => ({
  amount: state.cart.cartItems.length,
});

export const CartIconContainer = connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps)(CartIconComponent);
