import {gql} from 'apollo-boost';

export const getProductEditQuery = gql`
    query getProductEdit($id: Int!) {
        product(id: $id){
            id
            name
            stock
            priority
            category {
                id
            }
        }
    }
`;
