import {useQuery} from '@apollo/client';
import React, {memo} from 'react';
import {RouteComponentProps} from 'react-router';
import {ErrorComponent} from '../../../Component/Error/ErrorComponent';
import {LoadingComponent} from '../../../Component/Loading/LoadingComponent';
import {AdminForm} from '../../Component/Form/AdminForm';
import {AdminCrudPage} from '../../Component/Page/AdminCrudPage';
import {getOrganisationEditQuery} from '../../GraphQL/Query/getOrganisationEditQuery';
import {getOrganisationEdit} from '../../GraphQL/Query/types/getOrganisationEdit';
import {FormValues} from '../../Model/Form/FormValues';

type Props = RouteComponentProps<{
  id: string;
}>;

export const OrganisationAdminEditScreen: React.FC<Props> = memo(({match}) => {
  const {loading, error, data} = useQuery<getOrganisationEdit>(getOrganisationEditQuery, {
    fetchPolicy: 'network-only',
    variables: {id: parseInt(match.params.id, 10)},
  });

  let form;
  if (data) {
    const {categories, ...initialValues} = data.organisation;
    (initialValues as unknown as FormValues).categories = categories.map(category => category.id);
    form = (
      <AdminForm
        redirectUrl="/admin/organisation"
        initialValues={(initialValues as unknown as FormValues)}
        mutationName="editOrganisation"
      />
    );
  }

  return (
    <AdminCrudPage entityType="organisation" action="Edit">
      {loading && <LoadingComponent/>}
      {(!loading && (error || !data)) && <ErrorComponent/>}
      {form}
    </AdminCrudPage>
  );
}, (prevProps, nextProps) => prevProps.match.params.id === nextProps.match.params.id);

