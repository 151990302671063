import {IonText} from '@ionic/react';
import React, {memo} from 'react';

interface Props {
  required: boolean;
}

export const RequiredComponent: React.FC<Props> = memo(({required}) => {
  if (!required) {
    return null;
  }
  return <IonText color="danger">*</IonText>;
});
