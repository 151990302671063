import {gql} from 'apollo-boost';

export const getUserEditQuery = gql`
    query getUserEdit($id: Int!) {
        user(id: $id){
            id
            email
            username
            phoneNumber
            name
            role
            organisation {
                id
            }
        }
    }
`;
