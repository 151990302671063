import {IonToast} from '@ionic/react';
import React, {memo} from 'react';
import {ToastListProps} from '../Container/ToastListContainer';

export const ToastList: React.FC<ToastListProps> = memo(({toasts, hideToast}) => {
  const onDismiss = (id: number) => () => hideToast(id);

  const renderedToasts = toasts.map(toast => (
    <IonToast
      key={toast.id}
      isOpen
      message={toast.message}
      duration={toast.duration}
      onDidDismiss={onDismiss(toast.id)}
    />
  ));
  return <>{renderedToasts}</>;
});
