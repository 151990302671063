import {
  IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar,
} from '@ionic/react';
import React, {memo} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import {CategoryListComponent} from '../Component/CategoryList/CategoryListComponent';
import {SearchIconComponent} from '../Component/Search/SearchIconComponent';
import {CartFooterContainer} from '../Container/Component/CartFooterContainer';
import {CartIconContainer} from '../Container/Component/CartIconContainer';
import {Category} from '../Model/Category';
import {RootState} from '../Store';

export const CategoryListScreen: React.FC = memo(() => {
  const categories = useSelector<RootState, Category[]>(state => state.category);
  const {formatMessage} = useIntl();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton text={formatMessage({id: 'Label.Back'})} defaultHref="/"/>
          </IonButtons>
          <IonTitle><FormattedMessage id="Title.Categories"/></IonTitle>
          <IonButtons slot="end">
            <SearchIconComponent/>
            <CartIconContainer/>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollX={false} scrollY={false}>
        <CategoryListComponent categories={categories}/>
      </IonContent>
      <CartFooterContainer/>
    </IonPage>
  );
});
