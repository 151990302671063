import {CommandTypes} from '../../../Model/Queue/CommandType';
import {QueueCommand} from '../../../Model/Queue/QueueCommand';
import {QueueItem} from '../../../Model/Queue/QueueItem';
import {GraphQlClient} from '../../GraphQl/GraphQlClient';
import {StoreGateway} from '../../Store/StoreGateway';
import {DownloadCartCommand} from './DownloadCartCommand';
import {DownloadCategoriesCommand} from './DownloadCategoriesCommand';
import {DownloadFavoritesCommand} from './DownloadFavoritesCommand';
import {DownloadOrdersCommand} from './DownloadOrdersCommand';
import {DownloadProductsCommand} from './DownloadProductsCommand';
import {UploadCartCommand} from './UploadCartCommand';
import {UploadFavoritesCommand} from './UploadFavoritesCommand';
import {UploadOrderCommand} from './UploadOrderCommand';

export class CommandFactory {
  public constructor(private graphQlClient: GraphQlClient, private storeGateway: StoreGateway) {
  }

  public createFromQueueItem(queueItem: QueueItem): QueueCommand {
    switch (queueItem.commandType) {
      case CommandTypes.DownloadOrders:
        return new DownloadOrdersCommand(this.graphQlClient, this.storeGateway);
      case CommandTypes.DownloadCategories:
        return new DownloadCategoriesCommand(this.graphQlClient, this.storeGateway);
      case CommandTypes.DownloadProducts:
        return new DownloadProductsCommand(this.graphQlClient, this.storeGateway);
      case CommandTypes.DownloadFavorites:
        return new DownloadFavoritesCommand(this.graphQlClient, this.storeGateway);
      case CommandTypes.DownloadCart:
        return new DownloadCartCommand(this.graphQlClient, this.storeGateway);
      case CommandTypes.UploadCart:
        return new UploadCartCommand(this.graphQlClient, this.storeGateway);
      case CommandTypes.UploadFavorites:
        return new UploadFavoritesCommand(this.graphQlClient, this.storeGateway);
      case CommandTypes.UploadOrder:
        return new UploadOrderCommand(queueItem.data, this.graphQlClient, this.storeGateway);
      default:
        throw new Error(`invalid command ${queueItem.commandType as string}`);
    }
  }
}
