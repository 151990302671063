import {IonText} from '@ionic/react';
import React, {memo} from 'react';
import {FormattedMessage} from 'react-intl';
import {FormError} from '../../../Model/Form/FormError';

interface Props {
  errors: FormError[];
  fieldName: string;
}

export const ErrorList: React.FC<Props> = memo(({fieldName, errors}) => {
  if (errors.length === 0) {
    return null;
  }
  const fields = errors.map(error => (
    <IonText key={error.errorName} color="danger">
      <FormattedMessage id={`Admin.Form.Field.${fieldName}.Validation.${error.errorName}`} values={error.context}/>
    </IonText>
  ));
  return <>{fields}</>;
});
