import React, {memo} from 'react';
import {ServiceContainer} from '../../../../../Service/ServiceContainer/ServiceContainer';
import {FormFieldOption} from '../../../../Model/Form/FormFieldOption';
import {FormFieldProps} from '../../../../Model/Form/FormFieldProps';
import {RadioGroupField} from '../RadioGroupField';

const {translator} = ServiceContainer;

interface Props extends FormFieldProps {
  multiple?: boolean;
  value: string | string[];
}

const stockOptions: FormFieldOption[] = [
  {key: 1, label: translator.translate('Admin.Form.Field.stock.Value.Yes')},
  {key: 0, label: translator.translate('Admin.Form.Field.stock.Value.No')},
];

export const StockField: React.FC<Props> = memo(props => <RadioGroupField options={stockOptions} {...props}/>);
