import {
  IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar,
} from '@ionic/react';
import React, {memo} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

interface Props {
  backUrl?: string;
  title: string;
}

export const AdminPage: React.FC<Props> = memo(({title, backUrl, children}) => {
  const {formatMessage} = useIntl();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {backUrl && <IonBackButton text={formatMessage({id: 'Label.Back'})} defaultHref={backUrl}/>}
          </IonButtons>
          <IonTitle><FormattedMessage id={title}/></IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {children}
      </IonContent>
    </IonPage>
  );
});
