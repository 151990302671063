import React, {memo} from 'react';
import {FormFieldProps} from '../../../Model/Form/FormFieldProps';
import {OnChange} from '../../../Model/Form/OnChange';
import {TextField} from './TextField';

interface Props extends FormFieldProps {
  value: string | undefined | null;
}

export const PasswordField: React.FC<Props> = memo(props => {
  let {onChange, attributes, ...passthroughProps} = props;
  const onPasswordChange: OnChange = value => {
    if (value === '') {
      value = undefined;
    }
    onChange(value);
  };
  attributes = {type: 'password', pattern: 'password', ...attributes};
  return (
    <TextField {...passthroughProps} attributes={attributes} onChange={onPasswordChange}/>
  );
});
