import React, {memo} from 'react';
import {SortFunction} from '../../../Utils/SortFunction';
import {AdminListPage} from '../../Component/Page/AdminListPage';
import {removeProductMutation} from '../../GraphQL/Mutation/removeProductMutation';
import {getProductListQuery} from '../../GraphQL/Query/getProductListQuery';
import {getProductList_products} from '../../GraphQL/Query/types/getProductList';

const sort: SortFunction<getProductList_products> = (a, b) => b.priority - a.priority;

export const ProductAdminListScreen: React.FC = memo(() => (
  <AdminListPage entityType="product" getQuery={getProductListQuery} deleteMutation={removeProductMutation} sortFunction={sort}/>
));
