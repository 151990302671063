import {gql} from 'apollo-boost';

export const getOrganisationSelectQuery = gql`
    query getOrganisationSelect{
        organisations {
            id
            name
        }
    }
`;
