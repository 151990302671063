import {gql} from 'apollo-boost';

export const ordersQuery = gql`
    query OrdersQuery($deliveryDate: String!) {
        orders(where: {deliveryDate:{gte:$deliveryDate}}) {
            id
            deliveryDate
            orderDate
            remark
            uuid
            user{
                id
                name
            }
            orderLines {
                id
                amount
                productName
            }
        }
    }
`;
