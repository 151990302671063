import {Config} from '../Model/Environment/Config';

export const configDev: Config = {
  serverUrl: 'http://localhost:4000',
  publicKey: '-----BEGIN PUBLIC KEY-----\n'
                   + 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA5NscBSbETAakKX8hBZFr\n'
                   + 'FvTDMIG704Mb1Vf5p4psYUSddUoO3Zwmce0rusPsp7Ma2MjjD9+e8Th1zPaF4FkQ\n'
                   + 'TGd5mOUscUkthP9sJiAn8nli2ERqMeBdUJR6J92DW85FGMP8lMsMToXdhiO1GFKR\n'
                   + 'FmB4gXkCUg6Kf7CeWvjEL/G1N9UWitqz7jQ/foyiz09eunYG0uVe2n/PBeI908AF\n'
                   + 'bRcy7wMtcNmJC6oyKUR1c+eKcTdRgRJ78TgSo+U8dwSsyA7fw1olrPWFUOkv/c6y\n'
                   + 'eeVwXRxb/GFFheJ4V9+pvt3r3Itk2NU/8M49exvOlhoJXCnpTaTePKwV/iCcKiPz\n'
                   + 'ZQIDAQAB\n'
                   + '-----END PUBLIC KEY-----\n',
};
