import {IonButton, IonIcon} from '@ionic/react';
import {cart} from 'ionicons/icons';
import React, {memo} from 'react';
import {CartIconContainerProps} from '../../Container/Component/CartIconContainer';

export const CartIconComponent: React.FC<CartIconContainerProps> = memo(({amount}) => (
  <IonButton routerLink="/cart" className="cart-icon">
    <IonIcon slot="icon-only" icon={cart} className="cart-icon__icon"/>
    {amount ? <div className="cart-icon__amount">{amount}</div> : null}
  </IonButton>
));
