import {AuthenticationType} from '../Authentication/action';
import {Action} from '../action';
import {CategoryType} from './action';
import {CategoryState} from './state';

const initialState: CategoryState = [];

export const categoryReducer = (state = initialState, action: Action): CategoryState => {
  switch (action.type) {
    case CategoryType.CategoriesLoaded:
      return action.categories;
    case AuthenticationType.Authenticated:
      return action.isNewUser ? initialState : state;
    default:
      return state;
  }
};
