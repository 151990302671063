import {IonContent, IonPage} from '@ionic/react';
import React, {memo} from 'react';
import {AuthenticationFormContainer} from '../Container/Component/AuthenticationFormContainer';
import {useHideSplashScreen} from '../Hook/UseHideSplashScreen';

export const LoginScreen: React.FC = memo(() => {
  useHideSplashScreen();

  return (
    <IonPage>
      <IonContent>
        <div className="login">
          <div className="login__heading">
            <img className="login-form__logo" alt="Groentehal" src="/assets/images/logo.png"/>
          </div>
          <AuthenticationFormContainer/>
          <div className="login__logos"/>
        </div>
      </IonContent>
    </IonPage>
  );
});
