import React from 'react';
import ReactDOM from 'react-dom';
import {initialState} from './Config/initial-state';
import {AppProvider} from './Provider/AppProvider/AppProvider';
import {ServiceContainer} from './Service/ServiceContainer/ServiceContainer';
import * as serviceWorker from './serviceWorker';

const {configGateway, storeConfigurator, storeGateway} = ServiceContainer;
const {store, persistor} = storeConfigurator.initializeStore(initialState);
storeGateway.setStore(store);

configGateway.fetchEnvironment().then(() => {
  ReactDOM.render(<AppProvider store={store} persistor={persistor}/>, document.getElementById('root'));
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
