export enum CommandTypes {
  DownloadOrders = 'DownloadOrders',
  DownloadCategories = 'DownloadCategories',
  DownloadProducts = 'DownloadProducts',
  DownloadFavorites = 'DownloadFavorites',
  DownloadCart = 'DownloadCart',
  UploadCart = 'UploadCart',
  UploadFavorites = 'UploadFavorites',
  UploadOrder = 'UploadOrder',
}
