import {useQuery} from '@apollo/client';
import React, {memo} from 'react';
import {gql} from 'apollo-boost';
import {ErrorComponent} from '../../../Component/Error/ErrorComponent';
import {LoadingComponent} from '../../../Component/Loading/LoadingComponent';
import {FormValue} from '../../Model/Form/FormValue';
import {Mutation} from '../../Model/Form/Mutation';
import {MutationForm} from './MutationForm';

const query = gql`
    query IntrospectionQuery {
        __type(name: "Mutation") {
            fields {
                name
                args {
                    name
                    type {
                        ofType {
                            name
                            kind
                            inputFields {
                                name
                                description
                                type {
                                    name
                                    kind
                                    enumValues {
                                        name
                                    }
                                    ofType {
                                        name
                                        kind
                                        enumValues {
                                            name
                                        }
                                        ofType {
                                            name
                                            kind
                                            enumValues {
                                                name
                                            }
                                            ofType {
                                                name
                                                kind
                                                enumValues {
                                                    name
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

interface QueryResult {
  __type: {
    fields: Mutation[];
    name: string;
  };
}

interface Props {
  initialValues?: Record<string, FormValue>;
  mutationName: string;
  redirectUrl: string;
}

export const AdminForm: React.FC<Props> = memo(({mutationName, ...passThroughProps}) => {
  const {loading, error, data} = useQuery<QueryResult>(query, {fetchPolicy: 'network-only'});
  if (loading) {
    return <LoadingComponent/>;
  }
  if (error || !data) {
    return <ErrorComponent/>;
  }
  const mutation = data.__type.fields.find(field => field.name === mutationName);
  if (!mutation || !mutation.args[0]) {
    return <ErrorComponent/>;
  }

  return <MutationForm {...passThroughProps} mutation={mutation}/>;
});
