import {useQuery} from '@apollo/client';
import React, {memo} from 'react';
import {RouteComponentProps} from 'react-router';
import {ErrorComponent} from '../../../Component/Error/ErrorComponent';
import {LoadingComponent} from '../../../Component/Loading/LoadingComponent';
import {AdminForm} from '../../Component/Form/AdminForm';
import {AdminCrudPage} from '../../Component/Page/AdminCrudPage';
import {getProductEditQuery} from '../../GraphQL/Query/getProductEditQuery';
import {getProductEdit} from '../../GraphQL/Query/types/getProductEdit';
import {FormValues} from '../../Model/Form/FormValues';

type Props = RouteComponentProps<{
  id: string;
}>;

export const ProductAdminEditScreen: React.FC<Props> = memo(({match}) => {
  const {loading, error, data} = useQuery<getProductEdit>(getProductEditQuery, {
    fetchPolicy: 'network-only',
    variables: {id: parseInt(match.params.id, 10)},
  });

  let form;
  if (data) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const {__typename, category, ...initialValues} = data.product;
    (initialValues as unknown as FormValues).categoryId = category.id;
    form = (
      <AdminForm
        initialValues={(initialValues as unknown as FormValues)}
        mutationName="editProduct"
        redirectUrl="/admin/product"
      />
    );
  }

  return (
    <AdminCrudPage entityType="product" action="Edit">
      {loading && <LoadingComponent/>}
      {(!loading && (error || !data)) && <ErrorComponent/>}
      {form}
    </AdminCrudPage>
  );
}, (prevProps, nextProps) => prevProps.match.params.id === nextProps.match.params.id);

