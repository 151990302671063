import {AuthenticationType} from '../Authentication/action';
import {Action} from '../action';
import {ProductType} from './action';
import {ProductState} from './state';

const initialState: ProductState = [];

export const productReducer = (state = initialState, action: Action): ProductState => {
  switch (action.type) {
    case ProductType.ProductsLoaded:
      return action.products;
    case AuthenticationType.Authenticated:
      return action.isNewUser ? initialState : state;
    default:
      return state;
  }
};
