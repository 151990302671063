import {AuthenticationType} from '../Authentication/action';
import {Action} from '../action';
import {FavoriteType} from './action';
import {FavoriteState} from './state';

const initialState: FavoriteState = [];

export const favoriteReducer = (state = initialState, action: Action): FavoriteState => {
  switch (action.type) {
    case FavoriteType.FavoriteProductAdded:
      return [...state, action.productId];
    case FavoriteType.FavoriteProductRemoved:
      return state.filter(productId => productId !== action.productId);
    case FavoriteType.FavoritesLoaded:
      return action.favorites;
    case AuthenticationType.Authenticated:
      return action.isNewUser ? initialState : state;
    default:
      return state;
  }
};
