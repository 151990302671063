import {
  IonButton, IonContent, IonFooter, IonHeader, IonPage, IonTitle, IonToolbar,
} from '@ionic/react';
import React, {memo} from 'react';
import {FormattedMessage} from 'react-intl';
import {OrderListComponent} from '../Component/Order/OrderListComponent';
import {QueueRefresher} from '../Component/QueueRefresher/QueueRefresherComponent';
import {useHideSplashScreen} from '../Hook/UseHideSplashScreen';

export const HomeScreen: React.FC = memo(() => {
  useHideSplashScreen();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle><FormattedMessage id="Title.Orders"/></IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-content--cardlist-background">
        <QueueRefresher/>
        <OrderListComponent/>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButton routerLink="/categories" className="ion-button--as-footer" color="secondary">
            <FormattedMessage id="Label.NewOrder"/>
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
});
