import {gql} from 'apollo-boost';

export const getOrganisationListQuery = gql`
    query getOrganisationList{
        organisations{
            id
            name
        }
    }
`;
