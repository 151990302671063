import {useMutation} from '@apollo/client';
import {
  IonFab, IonFabButton, IonIcon, IonRippleEffect,
} from '@ionic/react';
import {gql} from 'apollo-boost';
import {save as saveIcon} from 'ionicons/icons';
import React, {memo, useCallback, useState} from 'react';
import {FieldDefinition} from '../../Model/Form/FieldDefinition';
import {FormErrorList} from '../../Model/Form/FormErrorList';
import {FormValue} from '../../Model/Form/FormValue';
import {OnChange} from '../../Model/Form/OnChange';
import {GraphQueryError} from '../../Model/GraphQL/GraphQueryError';
import {extractErrors} from '../../Service/Form/FormErrorExtractor';
import {DynamicFieldList} from './Layout/DynamicFieldList';

interface Props {
  fields: FieldDefinition[];
  initialValues: Record<string, FormValue>;
  mutation: string;
  onComplete: () => void;
}

export const GraphForm: React.FC<Props> = memo(({
  mutation, onComplete, fields, initialValues,
}) => {
  const [errors, setErrors] = useState<FormErrorList>({});
  const [formValues, setFormValues] = useState(initialValues);
  const [save, {loading}] = useMutation(gql(mutation), {
    onCompleted: onComplete,
    onError: e => {
      setErrors(extractErrors(e as unknown as GraphQueryError));
    },
  });

  const onClick = useCallback(() => save({variables: formValues}), [save, formValues]);
  const onChange = useCallback((fieldName: string): OnChange => value => {
    const untouchedErrors = {...errors};
    delete untouchedErrors[fieldName];
    setErrors(untouchedErrors);
    return setFormValues({...formValues, [fieldName]: value});
  }, [errors, setErrors, formValues, setFormValues]);
  return (
    <>
      <DynamicFieldList errors={errors} fields={fields} formValues={formValues} onChange={onChange}/>
      <IonFab vertical="bottom" horizontal="end" slot="fixed">
        <IonFabButton onClick={onClick}>
          {loading && <IonRippleEffect/>}
          <IonIcon icon={saveIcon}/>
        </IonFabButton>
      </IonFab>
    </>
  );
});
