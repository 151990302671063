import {Order} from '../../Model/Order';

export enum OrderType {
  OrdersLoaded = 'OrdersLoaded',
  OrderUpdated = 'OrderUpdated',
  OrderPlaced = 'OrderPlaced',
}

export interface OrdersLoadedAction {
  orders: Order[];
  type: OrderType.OrdersLoaded;
}

export interface OrderPlacedAction {
  order: Order;
  type: OrderType.OrderPlaced;
}

export interface OrderUpdatedAction {
  order: Order;
  type: OrderType.OrderUpdated;
}

export const ordersLoaded = (orders: Order[]): OrdersLoadedAction => ({
  type: OrderType.OrdersLoaded,
  orders,
});

export const orderPlaced = (order: Order): OrderPlacedAction => ({
  type: OrderType.OrderPlaced,
  order,
});

export const orderUpdated = (order: Order): OrderUpdatedAction => ({
  type: OrderType.OrderUpdated,
  order,
});

export type OrderActions =
    | OrdersLoadedAction
    | OrderPlacedAction
    | OrderUpdatedAction;
