import {IonApp, IonRouterOutlet} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import React, {memo, ReactNode} from 'react';
import {Redirect, Route} from 'react-router-dom';
import {AdminHomePage} from '../../Admin/Component/Page/AdminHomePage';
import {Role} from '../../Admin/GraphQL/global-types';
import {CategoryAdminAddScreen} from '../../Admin/Screen/Category/CategoryAdminAddScreen';
import {CategoryAdminEditScreen} from '../../Admin/Screen/Category/CategoryAdminEditScreen';
import {CategoryAdminListScreen} from '../../Admin/Screen/Category/CategoryAdminListScreen';
import {OrderAdminDetailScreen} from '../../Admin/Screen/Order/OrderAdminDetailScreen';
import {OrderAdminListScreen} from '../../Admin/Screen/Order/OrderAdminListScreen';
import {OrganisationAdminAddScreen} from '../../Admin/Screen/Organisation/OrganisationAdminAddScreen';
import {OrganisationAdminEditScreen} from '../../Admin/Screen/Organisation/OrganisationAdminEditScreen';
import {OrganisationAdminListScreen} from '../../Admin/Screen/Organisation/OrganisationAdminListScreen';
import {ProductAdminAddScreen} from '../../Admin/Screen/Product/ProductAdminAddScreen';
import {ProductAdminEditScreen} from '../../Admin/Screen/Product/ProductAdminEditScreen';
import {ProductAdminListScreen} from '../../Admin/Screen/Product/ProductAdminListScreen';
import {UserAdminAddScreen} from '../../Admin/Screen/User/UserAdminAddScreen';
import {UserAdminEditScreen} from '../../Admin/Screen/User/UserAdminEditScreen';
import {UserAdminListScreen} from '../../Admin/Screen/User/UserAdminListScreen';
import {AppContainerProps} from '../../Container/Component/AppContainer';
import {useHasRole} from '../../Hook/UseHasRole';
import {CartDetailScreen} from '../../Screen/CartDetailScreen';
import {CategoryDetailScreen} from '../../Screen/CategoryDetailScreen';
import {CategoryListScreen} from '../../Screen/CategoryListScreen';
import {ConfirmationScreen} from '../../Screen/ConfirmationScreen';
import {FavoriteProductsScreen} from '../../Screen/FavoriteProductsScreen';
import {HomeScreen} from '../../Screen/HomeScreen';
import {LoginScreen} from '../../Screen/LoginScreen';
import {OrderDetailScreen} from '../../Screen/OrderDetailScreen';
import {SearchScreen} from '../../Screen/SearchScreen';
import '../../Theme/theme.scss';
import {ToastListContainer} from '../../Toaster/Container/ToastListContainer';

const getUserRoutes = (): ReactNode[] => {
  let key = 0;
  return [
    <Route key={++key} path="/" component={HomeScreen} exact/>,
    <Route key={++key} path="/order/:uuid" component={OrderDetailScreen} exact/>,
    <Route key={++key} path="/categories" component={CategoryListScreen} exact/>,
    <Route key={++key} path="/category/:id" component={CategoryDetailScreen} exact/>,
    <Route key={++key} path="/favorites" component={FavoriteProductsScreen} exact/>,
    <Route key={++key} path="/cart" component={CartDetailScreen} exact/>,
    <Route key={++key} path="/confirmation" component={ConfirmationScreen} exact/>,
    <Route key={++key} path="/search" component={SearchScreen} exact/>,
  ];
};

const getAdminRoutes = (): ReactNode[] => {
  const homescreen = () => <Redirect to="/admin"/>;
  let key = 0;
  return [
    <Route key={++key} exact path="/" render={homescreen}/>,
    <Route key={++key} exact path="/admin" component={AdminHomePage}/>,
    <Route key={++key} exact path="/admin/user" component={UserAdminListScreen}/>,
    <Route key={++key} exact path="/admin/user/:id/edit" component={UserAdminEditScreen}/>,
    <Route key={++key} exact path="/admin/user/add" component={UserAdminAddScreen}/>,
    <Route key={++key} exact path="/admin/product" component={ProductAdminListScreen}/>,
    <Route key={++key} exact path="/admin/product/:id/edit" component={ProductAdminEditScreen}/>,
    <Route key={++key} exact path="/admin/product/add" component={ProductAdminAddScreen}/>,
    <Route key={++key} exact path="/admin/organisation" component={OrganisationAdminListScreen}/>,
    <Route key={++key} exact path="/admin/organisation/:id/edit" component={OrganisationAdminEditScreen}/>,
    <Route key={++key} exact path="/admin/organisation/add" component={OrganisationAdminAddScreen}/>,
    <Route key={++key} exact path="/admin/category" component={CategoryAdminListScreen}/>,
    <Route key={++key} exact path="/admin/category/:id/edit" component={CategoryAdminEditScreen}/>,
    <Route key={++key} exact path="/admin/category/add" component={CategoryAdminAddScreen}/>,
    <Route key={++key} exact path="/admin/order" component={OrderAdminListScreen}/>,
    <Route key={++key} exact path="/admin/order/:id/edit" component={OrderAdminDetailScreen}/>,
  ];
};

export const App: React.FC<AppContainerProps> = memo(({authenticated}) => (
  <IonApp>
    <IonReactRouter>
      {authenticated ? <AuthenticatedRouterOutlet/> : <AnonymousRouterOutlet/>}
    </IonReactRouter>
    <ToastListContainer/>
  </IonApp>
));

const AuthenticatedRouterOutlet: React.FC = memo(() => {
  const isUser = useHasRole(Role.User);
  const isAdmin = useHasRole(Role.Admin);
  return (
    <IonRouterOutlet>
      {isUser ? getUserRoutes() : []}
      {isAdmin ? getAdminRoutes() : []}
    </IonRouterOutlet>
  );
});

const AnonymousRouterOutlet: React.FC = memo(() => (
  <IonRouterOutlet>
    <Route path="/login" component={LoginScreen}/>
    <Redirect to="/login"/>
  </IonRouterOutlet>
));
