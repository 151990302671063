import {IonSearchbar, IonText} from '@ionic/react';
import React, {
  memo, useCallback, useEffect, useRef, useState,
} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

interface Props {
  minCharacters: number;
  onInput: (value: string) => void;
}

export const SearchBarComponent: React.FC<Props> = memo(({onInput, minCharacters}) => {
  const searchBar = useRef(null);
  const [searchInput, setSearchInput] = useState<string>('');

  const {formatMessage} = useIntl();
  const onChange = useCallback(e => {
    // @todo refactor code to resolve technical depth (eslint)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    setSearchInput(e.detail.value);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    onInput(e.detail.value.length >= minCharacters ? e.detail.value : '');
  }, [setSearchInput, minCharacters, onInput]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const element = searchBar.current as any;
    // @todo refactor code to resolve technical depth (eslint)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    element.getInputElement().then((input: HTMLInputElement) => input.focus());
  }, [searchBar]);

  const waitingForInputMessage = (
    <div className="ion-padding-top ion-padding-bottom ion-text-center">
      <IonText color="medium">
        <FormattedMessage id="Label.WaitingForInput" values={{charsNeeded: minCharacters - searchInput.length}}/>
      </IonText>
    </div>
  );

  return (
    <div className="custom-search__bar">
      <IonSearchbar
        onIonChange={onChange}
        debounce={500}
        placeholder={formatMessage({id: 'Label.Search'})}
        ref={searchBar}
      />
      <div className="custom-search__hint">
        {searchInput.length < minCharacters && waitingForInputMessage}
      </div>
    </div>
  );
});
