import {ApolloClient, from, HttpLink, InMemoryCache} from '@apollo/client';
import {ServerError} from '@apollo/client/link/utils';
import {onError} from '@apollo/client/link/error';
import {setContext} from '@apollo/client/link/context';
import {ConfigGateway} from '../Config/ConfigGateway';
import {StoreGateway} from '../Store/StoreGateway';
import {loggedOut} from '../../Store/Authentication/action';

export class GraphQlClient {
  public constructor(private configGateway: ConfigGateway, private storeGateway: StoreGateway) {
  }

  public getClient() {
    const httpLink = new HttpLink({
      uri: () => `${this.configGateway.getConfig().serverUrl}/graphql`,
    });

    const errorLink = onError(errorResponse => {
      if (errorResponse.networkError && (errorResponse.networkError as ServerError).statusCode === 401) {
        this.storeGateway.dispatch(loggedOut());
      }
    });

    const authLink = setContext((_, {headers}) => {
      // get the authentication token from local storage if it exists
      const {token} = this.storeGateway.getState().authentication;
      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : '',
        },
      };
    });

    return new ApolloClient({
      link: from([authLink, httpLink, errorLink]),
      cache: new InMemoryCache(),
    });
  }
}
