import {
  IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar,
} from '@ionic/react';
import React, {memo} from 'react';
import {FormattedMessage} from 'react-intl';

export const ConfirmationScreen: React.FC = memo(() => (
  <IonPage>
    <IonHeader>
      <IonToolbar color="primary">
        <IonTitle><FormattedMessage id="Title.Confirmation"/></IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent className="ion-padding ion-content--image-background">
      <h1><FormattedMessage id="Content.Confirmation.Heading"/></h1>
      <p><FormattedMessage id="Content.Confirmation.Text"/></p>
      <IonButton routerLink="/"><FormattedMessage id="Content.Confirmation.Return"/></IonButton>
    </IonContent>
  </IonPage>
));
