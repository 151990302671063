import {connect} from 'react-redux';
import {AuthenticationFormComponent} from '../../Component/Authentication/AuthenticationFormComponent';
import {Dispatch, RootState} from '../../Store';
import {tokenReceived, usernameEntered} from '../../Store/Authentication/action';

interface OwnProps {
}

type StateProps = OwnProps;

interface DispatchProps {
  tokenReceived: (token: string) => void;
  usernameEntered: (username: string) => void;
}

export type AuthenticationFormContainerProps = StateProps & DispatchProps;

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  tokenReceived: (token: string) => dispatch(tokenReceived(token)),
  usernameEntered: (username: string) => dispatch(usernameEntered(username)),
});

export const AuthenticationFormContainer = connect<StateProps, DispatchProps, OwnProps, RootState>(
  null,
  mapDispatchToProps,
)(AuthenticationFormComponent);
