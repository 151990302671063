import React, {memo} from 'react';
import {FormattedMessage} from 'react-intl';
import {useSelector} from 'react-redux';
import {Order} from '../../Model/Order';
import {RootState} from '../../Store';
import {OrderListItemComponent} from './OrderListItemComponent';

export const OrderListComponent: React.FC = memo(() => {
  const orders = useSelector<RootState, Order[]>(state => state.orders);
  const userId = useSelector<RootState, number>(state => state.authentication.decodedToken!.user.id);

  if (orders.length === 0) {
    return <div className="ion-padding"><FormattedMessage id="Label.NoOrders"/></div>;
  }

  const orderListItems = orders.map(order => <OrderListItemComponent key={order.uuid} order={order} userId={userId}/>);

  return <>{orderListItems}</>;
});
