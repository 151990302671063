import {EnvironmentEnum} from '../../Model/Environment/EnvironmentEnum';
import {AuthenticationType} from '../Authentication/action';
import {Middleware, MiddlewareApi} from '../index';
import {environmentSelected} from './action';

export class EnvironmentMiddleware {
  public middleware: Middleware = store => next => action => {
    next(action);

    switch (action.type) {
      case AuthenticationType.UsernameEntered:
        this.onUsernameEntered(store, action.username);
    }
  };

  private onUsernameEntered(store: MiddlewareApi, username: string) {
    const input = username.toLowerCase();
    if (input.startsWith('nb!')) {
      const env = input.split('!')[1];
      if (Object.values(EnvironmentEnum).includes(env as any)) {
        store.dispatch(environmentSelected(env as EnvironmentEnum));
      }
    }
  }
}
