import React, {memo, useCallback} from 'react';
import {FormFieldProps} from '../../../Model/Form/FormFieldProps';
import {TextField} from './TextField';

interface Props extends FormFieldProps {
  value: string | undefined | null;
}

export const IntegerField: React.FC<Props> = memo(({attributes, onChange, ...passthroughProps}) => {
  attributes = {type: 'number', inputmode: 'numeric', ...attributes};
  const onInput = useCallback(value => {
    const parsedNumber = parseInt(value, 10);
    if (isNaN(parsedNumber)) {
      return onChange(null);
    }
    return onChange(parsedNumber);
  }, [onChange]);
  return (
    <TextField {...passthroughProps} attributes={attributes} onChange={onInput}/>
  );
});
