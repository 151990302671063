import {IonCard, IonRippleEffect} from '@ionic/react';
import React, {memo, useCallback} from 'react';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import {VariableSizeList} from 'react-window';
import {Category} from '../../Model/Category';
import {CategoryListItemComponent} from './CategoryListItemComponent';

interface Props {
  categories: Category[];
}

const FavoriteCategory: React.ReactNode = (
  <div className="first-child-spacing-fix">
    <IonCard class="category-card category-card--favorites">
      <Link to="/favorites" className="link-as-block ion-activatable">
        <h2><FormattedMessage id="Label.FavoriteProducts"/></h2>
        <IonRippleEffect/>
      </Link>
    </IonCard>
  </div>
);

export const CategoryListComponent: React.FC<Props> = memo(({categories}) => {
  const Row = useCallback((data: { index: number; style: any }) => (
    <div style={data.style}>
      {data.index === 0 ? FavoriteCategory
        : <CategoryListItemComponent category={categories[data.index - 1]} key={categories[data.index - 1].id}/>}
    </div>
  ), [categories]);

  // first item gets a larger height because of the required margin top
  const getItemSize = (index: number) => (index === 0 ? 216 : 200);

  const CategoryList = useCallback((data: { height: number; width: number }) => (
    <VariableSizeList
      height={data.height}
      width={data.width}
      itemCount={categories.length + 1}
      itemSize={getItemSize}
    >
      {Row}
    </VariableSizeList>
  ), [categories, Row]);

  return (
    <div className="category-list">
      <AutoSizer>
        {CategoryList}
      </AutoSizer>
    </div>
  );
});
