import {gql} from 'apollo-boost';

export const getUserListQuery = gql`
    query getUserList{
        users{
            id
            name
            organisation {
                id
                name
            }
            email
            phoneNumber
        }
    }
`;
